<template>
  <section class="videos">
    <h2>Clonando Landing Page</h2>
    <button class="seta voltar" @click="voltarSlide()">
      <Svgs nome="setinha" />
    </button>
    <button class="seta avancar" @click="avancarSlide()">
      <Svgs nome="setinha" />
    </button>
    <div class="slide" ref="slider">
      <div class="item" v-for="(video, index) in state.videos" :key="`video-${index}`" @click="storeModal.abrirModalVideo('verVideo', video.titulo, video.descricao, video.url)">
        <div class="capa" :style="`background-image: url('${video.capa}')`">
          <h4>{{ video.duracao }}</h4>
        </div>
        <div class="texto">
          <h3>{{ video.titulo }}</h3>
          <p>{{ video.descricao }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useStoreModal } from '@stores'
import Svgs from '@svgs'

const slider = ref(null)
const storeModal = useStoreModal()

function avancarSlide() {
  if (slider.value) {
    slider.value.scrollBy({
      left: 300,
      behavior: 'smooth'
    })
  }
}

function voltarSlide() {
  if (slider.value) {
    slider.value.scrollBy({
      left: -300,
      behavior: 'smooth'
    })
  }
}

const state = reactive({
  videos: [
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/3b57b9db-d189-4522-9457-37025397ea54/thumbnail_dd93eed0.jpg',
      duracao: '4:44',
      titulo: 'A landing page que vamos clonar',
      descricao: 'Primeiro passo para clonar a landing page do curso.',
      url: 'https://iframe.mediadelivery.net/embed/367773/3b57b9db-d189-4522-9457-37025397ea54?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/33353580-108d-4982-869a-04a7fb57ac4a/thumbnail_3cd845d3.jpg',
      duracao: '12:24',
      titulo: 'Como baixar qualquer imagem de qualquer site',
      descricao: 'Aprenda a baixar imagens de qualquer site para usar no seu projeto.',
      url: 'https://iframe.mediadelivery.net/embed/367773/33353580-108d-4982-869a-04a7fb57ac4a?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/8af5e9dc-4eec-4442-baff-dfd6df35408c/thumbnail_aad3a399.jpg',
      duracao: '13:03',
      titulo: 'Como funciona a tecnologia que usaremos',
      descricao: 'Entenda a tecnologia utilizada para clonar landing pages.',
      url: 'https://iframe.mediadelivery.net/embed/367773/8af5e9dc-4eec-4442-baff-dfd6df35408c?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/d1f44162-d5d5-40aa-ad3a-605bc3838ca8/thumbnail_ca77eff3.jpg',
      duracao: '10:15',
      titulo: 'Criando hero da landing page',
      descricao: 'Desenvolvimento da seção hero da landing page clonada.',
      url: 'https://iframe.mediadelivery.net/embed/367773/d1f44162-d5d5-40aa-ad3a-605bc3838ca8?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/3743e337-1c9a-45ef-a77a-964eca4286b9/thumbnail_1e2e0908.jpg',
      duracao: '10:02',
      titulo: 'Construindo seção com checklist',
      descricao: 'Como construir uma seção funcional com checklist na landing page.',
      url: 'https://iframe.mediadelivery.net/embed/367773/3743e337-1c9a-45ef-a77a-964eca4286b9?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/5ddaea27-467b-4e8b-926b-a07bd9c22c13/thumbnail_8ed6686f.jpg',
      duracao: '8:14',
      titulo: 'Seção de texto simples de fazer',
      descricao: 'Aprenda a criar uma seção de texto simples e eficiente para sua landing page.',
      url: 'https://iframe.mediadelivery.net/embed/367773/5ddaea27-467b-4e8b-926b-a07bd9c22c13?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/6f753576-1b0b-4cc5-94d8-b492a91c2998/thumbnail_1eb2860b.jpg',
      duracao: '17:24',
      titulo: 'Uma seção de quadros na horizontal',
      descricao: 'Criando uma seção com quadros alinhados na horizontal de forma responsiva.',
      url: 'https://iframe.mediadelivery.net/embed/367773/6f753576-1b0b-4cc5-94d8-b492a91c2998?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/3c08bdb3-fcbf-4628-bed0-8b567a7c4392/thumbnail_6236cfa3.jpg',
      duracao: '6:46',
      titulo: 'Duplicando seção em segundos',
      descricao: 'Descubra como duplicar seções rapidamente e economizar tempo no desenvolvimento.',
      url: 'https://iframe.mediadelivery.net/embed/367773/3c08bdb3-fcbf-4628-bed0-8b567a7c4392?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/10ec2a56-ae83-4fcb-a73c-6640ea96aee8/thumbnail_55e9a564.jpg',
      duracao: '13:58',
      titulo: 'Uma seção com vários quadrinhos',
      descricao: 'Criação de uma seção com múltiplos quadros organizados.',
      url: 'https://iframe.mediadelivery.net/embed/367773/10ec2a56-ae83-4fcb-a73c-6640ea96aee8?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/a42c1877-e52f-4874-96d8-626580e8fc3a/thumbnail_aeeb1396.jpg',
      duracao: '15:20',
      titulo: 'Clonando seção com quadros na horizontal',
      descricao: 'Aprenda a clonar e estruturar seções com quadros de forma prática.',
      url: 'https://iframe.mediadelivery.net/embed/367773/a42c1877-e52f-4874-96d8-626580e8fc3a?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/ca1d50ba-351c-4df7-9889-57874cf08b50/thumbnail_8f55ec18.jpg',
      duracao: '10:02',
      titulo: 'Seção de Box com imagens',
      descricao: 'Criando uma seção em box com imagens para destacar informações importantes.',
      url: 'https://iframe.mediadelivery.net/embed/367773/ca1d50ba-351c-4df7-9889-57874cf08b50?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/193d72cf-d82b-47ee-901f-dcf4b5799688/thumbnail_742ce313.jpg',
      duracao: '7:03',
      titulo: 'Criando seção com HTML e CSS',
      descricao: 'Aprenda a construir uma seção do zero utilizando apenas HTML e CSS.',
      url: 'https://iframe.mediadelivery.net/embed/367773/193d72cf-d82b-47ee-901f-dcf4b5799688?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/51ae81aa-0a59-4b4f-b494-c4625ca1d135/thumbnail_76dd37f3.jpg',
      duracao: '7:10',
      titulo: 'Uma seção com vários vídeos',
      descricao: 'Como estruturar uma seção que contém múltiplos vídeos de forma organizada.',
      url: 'https://iframe.mediadelivery.net/embed/367773/51ae81aa-0a59-4b4f-b494-c4625ca1d135?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/707e8806-05fd-4829-aa52-0551ede0e550/thumbnail_63c3ad6e.jpg',
      duracao: '15:57',
      titulo: 'Agora faremos a seção de preço',
      descricao: 'Passo a passo para criar uma seção de preços clara e eficiente.',
      url: 'https://iframe.mediadelivery.net/embed/367773/707e8806-05fd-4829-aa52-0551ede0e550?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/949dad9b-1401-4d9f-9713-52b4819df929/thumbnail_9afc4ef9.jpg',
      duracao: '5:14',
      titulo: 'Seção super simples de criar',
      descricao: 'Aprenda a desenvolver uma seção simples, mas altamente funcional.',
      url: 'https://iframe.mediadelivery.net/embed/367773/949dad9b-1401-4d9f-9713-52b4819df929?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/32334c09-5dad-478d-b0e5-bcac44932fc5/thumbnail_c53cacba.jpg',
      duracao: '9:05',
      titulo: 'Criando seção de dúvidas e FAQ',
      descricao: 'Construção de uma seção de perguntas frequentes para esclarecer dúvidas dos visitantes.',
      url: 'https://iframe.mediadelivery.net/embed/367773/32334c09-5dad-478d-b0e5-bcac44932fc5?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/96b3b4b0-ff5a-460a-93d8-bee35b7b97b9/thumbnail_9913ff61.jpg',
      duracao: '3:00',
      titulo: 'Bora criar a seção de garantia',
      descricao: 'Desenvolvimento de uma seção de garantia para reforçar a confiança do usuário.',
      url: 'https://iframe.mediadelivery.net/embed/367773/96b3b4b0-ff5a-460a-93d8-bee35b7b97b9?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/f73a4712-ca13-4dd0-a9d5-1b5b463c8abb/thumbnail_2c794092.jpg',
      duracao: '22:24',
      titulo: 'Criando versão mobile da landing page',
      descricao: 'Aprenda a adaptar sua landing page para dispositivos móveis.',
      url: 'https://iframe.mediadelivery.net/embed/367773/f73a4712-ca13-4dd0-a9d5-1b5b463c8abb?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    },
    {
      capa: 'https://vz-eef86d96-c68.b-cdn.net/b6e03fe0-440d-4f31-83cf-3e2740e6efdf/thumbnail_618b3c73.jpg',
      duracao: '1:57',
      titulo: 'Landing Page finalizada com sucesso',
      descricao: 'Últimos ajustes e revisão final para a entrega da landing page completa.',
      url: 'https://iframe.mediadelivery.net/embed/367773/b6e03fe0-440d-4f31-83cf-3e2740e6efdf?autoplay=true&loop=false&muted=false&preload=true&responsive=false'
    }
  ]
})
</script>

<style scoped>
section.videos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #0c0c0e;
  position: relative;
}
h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco-fixo);
  padding: 0 0 0 80px;
}
button.seta {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  z-index: 3;
}
button.seta.avancar:hover svg {
  transform: rotate(-90deg) scale(1.5);
}
button.seta.voltar:hover svg {
  transform: rotate(90deg) scale(1.5);
}
button.seta svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}
button.seta.avancar {
  right: 0;
  bottom: 0;
  background: linear-gradient(-90deg, #0c0c0e 20%, #0c0c0e00);
  padding: 0 15px 50px 40px;
}
button.seta.avancar svg {
  transform: rotate(-90deg);
}
button.seta.voltar {
  left: 0;
  bottom: 0;
  background: linear-gradient(90deg, #0c0c0e 20%, #0c0c0e00);
  padding: 0 40px 50px 15px;
}
button.seta.voltar svg {
  transform: rotate(90deg);
}
.slide {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 60px 80px 80px;
  overflow-x: scroll;
  scrollbar-width: none;
}
.slide::-webkit-scrollbar {
  display: none;
}
.slide .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  min-width: 280px;
  margin: 0 20px 0 0;
  background-color: #141416;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.slide .item:hover {
  background-color: #1b1b1d;
}
.slide .item .capa {
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
  position: relative;
}
.slide .item .capa h4 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  background-color: #000000;
  padding: 2px 4px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 5px;
}
.slide .item .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.slide .item .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  line-height: 1.5;
}
.slide .item .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 0 0;
}
@media screen and (max-width: 1000px) {
  h2 {
    font-family: var(--bold);
    font-size: var(--f3);
    color: var(--cor-branco-fixo);
    padding: 0 0 0 20px;
  }
  button.seta {
    display: none;
  }
  .slide {
    padding: 20px 5px 80px 20px;
  }
  .slide .item {
    margin: 0 15px 0 0;
  }
}
</style>
