export default [
  {
    path: '/criar/:hotlinkTemplate',
    name: 'criar',
    component: () => import('../components/criar/Criar.vue'),
    meta: {
      logged: true,
      title: 'Crie e Edite seu site | UnicPages.com',
      layout: 'default-layout'
    }
  },
  {
    path: '/criar/:hotlinkTemplate/desktop',
    name: 'criarDesktop',
    component: () => import('../components/criar/versoes/Desktop.vue'),
    meta: {
      logged: true,
      title: 'Desktop | UnicPages.com',
      layout: 'default-layout'
    }
  },
  {
    path: '/criar/:hotlinkTemplate/mobile',
    name: 'criarMobile',
    component: () => import('../components/criar/versoes/Mobile.vue'),
    meta: {
      logged: true,
      title: 'Mobile | UnicPages.com',
      layout: 'default-layout'
    }
  }
]
