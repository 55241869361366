<template>
  <div id="secoes">
    <!-- Seções de Navbar -->
    <div class="nome um">
      <h2>Seções de Menu</h2>
      <p>Usadas para ficar no topo do site</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'navbar')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="botoes">
          <h3>{{ secao.nome }}</h3>
          <button class="um" @click="secao.acao">Adicionar</button>
        </div>
      </div>
    </div>
    <!-- Seção sobre xxx -->
    <div class="nome">
      <h2>Seções de Topo</h2>
      <p>Usadas como seções principais do site</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'topo')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="botoes">
          <h3>{{ secao.nome }}</h3>
          <button class="um" @click="secao.acao">Adicionar</button>
        </div>
      </div>
    </div>
    <!-- Seção sobre xxx -->
    <div class="nome">
      <h2>Seções de Quadros</h2>
      <p>Usadas para exibir mais informações</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'quadro')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="botoes">
          <h3>{{ secao.nome }}</h3>
          <button class="um" @click="secao.acao">Adicionar</button>
        </div>
      </div>
    </div>
    <!-- Seção sobre xxx -->
    <div class="nome">
      <h2>Seções de Planos</h2>
      <p>Usadas para exibir preços e planos</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'plano')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="botoes">
          <h3>{{ secao.nome }}</h3>
          <button class="um" @click="secao.acao">Adicionar</button>
        </div>
      </div>
    </div>
    <!-- Seção sobre xxx -->
    <div class="nome">
      <h2>Seções de Slide</h2>
      <p>Usadas para carrosséis e slides</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'slide')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="botoes">
          <h3>{{ secao.nome }}</h3>
          <button class="um" @click="secao.acao">Adicionar</button>
        </div>
      </div>
    </div>
    <!-- Seção sobre xxx -->
    <div class="nome">
      <h2>Seções de dúvidas</h2>
      <p>Usadas para FAQ e dúvidas frequentes</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'duvida')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="botoes">
          <h3>{{ secao.nome }}</h3>
          <button class="um" @click="secao.acao">Adicionar</button>
        </div>
      </div>
    </div>
    <!-- Seção sobre xxx -->
    <div class="nome">
      <h2>Seções de formulários</h2>
      <p>Usadas para captura de dados e leads</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'formulario')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="botoes">
          <h3>{{ secao.nome }}</h3>
          <button class="um upgrade" @click="router.push('/planos')" v-if="storePerfil.usuario?.assinatura?.tipo === 'free'">Upgrade</button>
          <button class="um" @click="secao.acao" v-else>Adicionar</button>
        </div>
      </div>
    </div>
    <!-- Seção sobre xxx -->
    <div class="nome">
      <h2>Seções de footer</h2>
      <p>Usadas para final do site</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'footer')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="botoes">
          <h3>{{ secao.nome }}</h3>
          <button class="um" @click="secao.acao">Adicionar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive } from 'vue'
import { useStoreCriar, useStoreAjustes, useStoreModal, useStorePerfil } from '@stores'
import { helperId } from '@helpers'
import { useRouter } from 'vue-router'

import { useStoreNav1Desktop } from '@components/criar/secoes/padrao/nav/1/versaoDesktop/store/index.js'
import { useStoreNav2Desktop } from '@components/criar/secoes/padrao/nav/2/versaoDesktop/store/index.js'
import { useStoreNav3Desktop } from '@components/criar/secoes/padrao/nav/3/versaoDesktop/store/index.js'
import { useStoreNav4Desktop } from '@components/criar/secoes/padrao/nav/4/versaoDesktop/store/index.js'
import { useStoreTopo1Desktop } from '@components/criar/secoes/padrao/topo/1/versaoDesktop/store/index.js'
import { useStoreTopo2Desktop } from '@components/criar/secoes/padrao/topo/2/versaoDesktop/store/index.js'
import { useStoreTopo3Desktop } from '@components/criar/secoes/padrao/topo/3/versaoDesktop/store/index.js'
import { useStoreTopo4Desktop } from '@components/criar/secoes/padrao/topo/4/versaoDesktop/store/index.js'
import { useStoreTopo5Desktop } from '@components/criar/secoes/padrao/topo/5/versaoDesktop/store/index.js'
import { useStoreQuadro1Desktop } from '@components/criar/secoes/padrao/quadro/1/versaoDesktop/store/index.js'
import { useStoreQuadro2Desktop } from '@components/criar/secoes/padrao/quadro/2/versaoDesktop/store/index.js'
import { useStoreQuadro3Desktop } from '@components/criar/secoes/padrao/quadro/3/versaoDesktop/store/index.js'
import { useStoreDuvida1Desktop } from '@components/criar/secoes/padrao/duvida/1/versaoDesktop/store/index.js'
import { useStoreFormulario1Desktop } from '@components/criar/secoes/padrao/formulario/1/versaoDesktop/store/index.js'
import { useStoreFormulario2Desktop } from '@components/criar/secoes/padrao/formulario/2/versaoDesktop/store/index.js'
import { useStoreFormulario3Desktop } from '@components/criar/secoes/padrao/formulario/3/versaoDesktop/store/index.js'
import { useStoreFormulario4Desktop } from '@components/criar/secoes/padrao/formulario/4/versaoDesktop/store/index.js'
import { useStoreFormulario5Desktop } from '@components/criar/secoes/padrao/formulario/5/versaoDesktop/store/index.js'
import { useStoreFormulario6Desktop } from '@components/criar/secoes/padrao/formulario/6/versaoDesktop/store/index.js'
import { useStoreFormulario7Desktop } from '@components/criar/secoes/padrao/formulario/7/versaoDesktop/store/index.js'
import { useStorePlano1Desktop } from '@components/criar/secoes/padrao/plano/1/versaoDesktop/store/index.js'
import { useStorePlano2Desktop } from '@components/criar/secoes/padrao/plano/2/versaoDesktop/store/index.js'
import { useStorePlano3Desktop } from '@components/criar/secoes/padrao/plano/3/versaoDesktop/store/index.js'
import { useStoreSlide1Desktop } from '@components/criar/secoes/padrao/slide/1/versaoDesktop/store/index.js'
import { useStoreFooter1Desktop } from '@components/criar/secoes/padrao/footer/1/versaoDesktop/store/index.js'
import { useStoreFooter2Desktop } from '@components/criar/secoes/padrao/footer/2/versaoDesktop/store/index.js'

const emitter = inject('emitter')
const storeCriar = useStoreCriar()
const storeAjustes = useStoreAjustes()
const storeModal = useStoreModal()
const storePerfil = useStorePerfil()
const router = useRouter()

function adicionarSecao(secao, storeFunc) {
  const id = helperId.random(12)
  const store = sanitizarStore(storeFunc())

  storeCriar.adicionarSecao(secao, id, true)
  storeAjustes.adicionarSecao(secao, id, store, true)
  emitter.emit('renderizarComponentesTela')
  emitter.emit('atualizarCamadasSite')
  storeModal.fecharModal('secoesDesktop')
}

function sanitizarStore(store) {
  const clone = { ...store }

  for (const key in clone) {
    if (key.startsWith('_')) delete clone[key]
    if (key.startsWith('$')) delete clone[key]
  }

  return JSON.parse(JSON.stringify(clone))
}

const state = reactive({
  secoes: [
    // Navbar
    {
      nome: 'Nav Nº 1',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/nav-1.png',
      acao: () => adicionarSecao('Nav1Desktop', useStoreNav1Desktop),
      categoria: 'navbar'
    },
    {
      nome: 'Nav Nº 2',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/nav-2.png',
      acao: () => adicionarSecao('Nav2Desktop', useStoreNav2Desktop),
      categoria: 'navbar'
    },
    {
      nome: 'Nav Nº 3',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/nav-3.png',
      acao: () => adicionarSecao('Nav3Desktop', useStoreNav3Desktop),
      categoria: 'navbar'
    },
    {
      nome: 'Nav Nº 4',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/nav-4.png',
      acao: () => adicionarSecao('Nav4Desktop', useStoreNav4Desktop),
      categoria: 'navbar'
    },
    // Topo
    {
      nome: 'Topo Nº 1',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/topo-1.png',
      acao: () => adicionarSecao('Topo1Desktop', useStoreTopo1Desktop),
      categoria: 'topo'
    },
    {
      nome: 'Topo Nº 2',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/topo-2.png',
      acao: () => adicionarSecao('Topo2Desktop', useStoreTopo2Desktop),
      categoria: 'topo'
    },
    {
      nome: 'Topo Nº 3',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/topo-3.png',
      acao: () => adicionarSecao('Topo3Desktop', useStoreTopo3Desktop),
      categoria: 'topo'
    },
    {
      nome: 'Topo Nº 4',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/topo-4.png',
      acao: () => adicionarSecao('Topo4Desktop', useStoreTopo4Desktop),
      categoria: 'topo'
    },
    {
      nome: 'Topo Nº 5',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/topo-5.png',
      acao: () => adicionarSecao('Topo5Desktop', useStoreTopo5Desktop),
      categoria: 'topo'
    },
    // Quadros
    {
      nome: 'Quadro Nº 1',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/quadro-1.png',
      acao: () => adicionarSecao('Quadro1Desktop', useStoreQuadro1Desktop),
      categoria: 'quadro'
    },
    {
      nome: 'Quadro Nº 2',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/quadro-2.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreQuadro2Desktop),
      categoria: 'quadro'
    },
    {
      nome: 'Quadro Nº 3',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/quadro-3.png',
      acao: () => adicionarSecao('Quadro3Desktop', useStoreQuadro3Desktop),
      categoria: 'quadro'
    },
    // Planos
    {
      nome: 'Plano Nº 1',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/plano-1.png',
      acao: () => adicionarSecao('Plano1Desktop', useStorePlano1Desktop),
      categoria: 'plano'
    },
    {
      nome: 'Plano Nº 2',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/plano-2.png',
      acao: () => adicionarSecao('Plano2Desktop', useStorePlano2Desktop),
      categoria: 'plano'
    },
    {
      nome: 'Plano Nº 3',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/plano-3.png',
      acao: () => adicionarSecao('Plano3Desktop', useStorePlano3Desktop),
      categoria: 'plano'
    },
    // Slide
    {
      nome: 'Slide Nº 1',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/slide-1.png',
      acao: () => adicionarSecao('Slide1Desktop', useStoreSlide1Desktop),
      categoria: 'slide'
    },
    // Dúvidas
    {
      nome: 'Dúvida Nº 1',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/duvida-1.png',
      acao: () => adicionarSecao('Duvida1Desktop', useStoreDuvida1Desktop),
      categoria: 'duvida'
    },
    // Forms
    {
      nome: 'Form Nº 1',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/formulario-1.png',
      acao: () => adicionarSecao('Formulario1Desktop', useStoreFormulario1Desktop),
      categoria: 'formulario'
    },
    {
      nome: 'Form Nº 2',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/formulario-2.png',
      acao: () => adicionarSecao('Formulario2Desktop', useStoreFormulario2Desktop),
      categoria: 'formulario'
    },
    {
      nome: 'Form Nº 3',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/formulario-3.png',
      acao: () => adicionarSecao('Formulario3Desktop', useStoreFormulario3Desktop),
      categoria: 'formulario'
    },
    {
      nome: 'Form Nº 4',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/formulario-4.png',
      acao: () => adicionarSecao('Formulario4Desktop', useStoreFormulario4Desktop),
      categoria: 'formulario'
    },
    {
      nome: 'Form Nº 5',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/formulario-5.png',
      acao: () => adicionarSecao('Formulario5Desktop', useStoreFormulario5Desktop),
      categoria: 'formulario'
    },
    {
      nome: 'Form Nº 6',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/formulario-6.png',
      acao: () => adicionarSecao('Formulario6Desktop', useStoreFormulario6Desktop),
      categoria: 'formulario'
    },
    {
      nome: 'Form Nº 7',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/formulario-7.png',
      acao: () => adicionarSecao('Formulario7Desktop', useStoreFormulario7Desktop),
      categoria: 'formulario'
    },
    // Footer
    {
      nome: 'Footer Nº 1',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/footer-1.png',
      acao: () => adicionarSecao('Footer1Desktop', useStoreFooter1Desktop),
      categoria: 'footer'
    },
    {
      nome: 'Footer Nº 2',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/capa/footer-2.png',
      acao: () => adicionarSecao('Footer2Desktop', useStoreFooter2Desktop),
      categoria: 'footer'
    }
  ]
})
</script>

<style scoped>
#secoes {
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: fadeIn 0.3s linear;
}
.secoes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.nome {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0 20px 0;
  padding: 30px 0 0 0;
  border-top: 2px solid var(--cor-cinza-3);
}

.nome.um {
  padding: 0;
  margin: 0 0 20px 0;
  border-top: none;
}

.nome h2 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 10px 0 0;
}

.nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.secao {
  display: flex;
  flex-direction: column;
  width: calc(25% - 10px);
  margin: 0 10px 20px 0;
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--cor-cinza-3);
}

.secao .capa {
  width: 100%;
}

.secao .capa .tamanho {
  position: relative;
  padding-bottom: 56%;
  width: 100%;
  height: 100%;
}

.secao .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-preto);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
}

.secao .botoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-3);
  padding: 10px 10px 10px 20px;
  border-radius: 0 0 10px 10px;
}

.secao .botoes button {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  border-radius: 10px;
  padding: 8px 13px;
  transition: all 0.3s;
}

.secao .botoes button.upgrade {
  background-color: var(--cor-laranja);
}

.secao .botoes button.upgrade:hover {
  background-color: var(--cor-laranja-escuro);
}

.secao.breve {
  pointer-events: none;
}

.secao .botoes button:hover {
  background-color: var(--cor-azul-escuro);
}

.secao .botoes h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .secao {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 20px 0;
    position: relative;
    border-radius: 10px;
  }

  .nome {
    flex-direction: column;
    align-items: flex-start;
  }

  .nome h2 {
    margin: 0 0 5px 0;
  }
}
</style>
