<template>
  <section class="plano" :class="{ ocultarElemento: state.store?.fundo?.hidden }">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarDesktop />
    <ElementoSecao class="conteudo" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <!-- itens -->
      <ElementoDivSemFundo class="itens" :store="state.store" :elemento="prepararEvento('fundoItens')" @secaoSemFundo="secaoSemFundo">
        <ElementoQuadro v-for="item in items" :key="item" class="item" :store="state.store" :elemento="prepararEvento(`fundoQuadro${item}`)" @quadro="quadro">
          <ElementoImg class="icone" :store="state.store" :elemento="prepararEvento(`nomeIconeQuadro${item}`)" @imagem="imagem" />
          <ElementoH2 :store="state.store" :elemento="prepararEvento(`nomeTituloQuadro${item}`)" @texto="texto" />
          <ElementoP :store="state.store" :elemento="prepararEvento(`nomeSubtituloQuadro${item}`)" @texto="texto" />

          <div class="preco">
            <div class="parte um">
              <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoUmQuadro${item}`)" @texto="texto" />
              <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoDoisQuadro${item}`)" @texto="texto" />
            </div>
            <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoTresQuadro${item}`)" @texto="texto" />
            <div class="parte">
              <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoQuatroQuadro${item}`)" @texto="texto" />
              <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoCincoQuadro${item}`)" @texto="texto" />
            </div>
          </div>

          <ElementoH2 :store="state.store" :elemento="prepararEvento(`listaTituloQuadro${item}`)" @texto="texto" />

          <ElementoQuadro v-for="parte in partes" :key="parte" class="parte" :store="state.store" :elemento="prepararEvento(`listaParte${parte}Quadro${item}`)" @quadro="quadro">
            <ElementoImg :store="state.store" :elemento="prepararEvento(`iconeParte${parte}Quadro${item}`)" @imagem="imagem" />
            <ElementoH2 :store="state.store" :elemento="prepararEvento(`textoParte${parte}Quadro${item}`)" @texto="texto" />
          </ElementoQuadro>

          <ElementoButton :store="state.store" :elemento="prepararEvento(`botaoUmQuadro${item}`)" @botao="botao" />
        </ElementoQuadro>
      </ElementoDivSemFundo>

      <ElementoDivisor :store="state.store" :elemento="prepararEvento('divisor')" @divisor="divisor" class="divisor" />
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarDesktop from '@components/criar/partials/EditarDesktop.vue'

import ElementoH2 from '@components/criar/elementos/h2.vue'
import ElementoP from '@components/criar/elementos/p.vue'
import ElementoButton from '@components/criar/elementos/button.vue'
import ElementoImg from '@components/criar/elementos/img.vue'
import ElementoDivisor from '@components/criar/elementos/divisor.vue'
import ElementoQuadro from '@components/criar/elementos/quadro.vue'
import ElementoSecao from '@components/criar/elementos/secao.vue'
import ElementoDivSemFundo from '@components/criar/elementos/divSemFundo.vue'
import ElementoAvisoFixed from '@components/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const items = ['Um', 'Dois', 'Tres']

const partes = ['Um', 'Dois', 'Tres', 'Quatro', 'Cinco', 'Seis', 'Sete']

const state = reactive({
  indiceSecao: null,
  store: {}
})

function imagem(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagem', { evento })
}

function divisor(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesDivisor', { evento })
}

function texto(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesTexto', { evento })
}

function botao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesBotao', { evento })
}

function quadro(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesQuadro', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function secaoSemFundo(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecaoSemFundo', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'desktop'
  }
  return evento
}

function storeDaSecao() {
  const storeDesktop = storeAjustes.secoes.desktop
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeDesktop.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
.itens {
  display: flex;
  position: relative;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 2;
}

.preco {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 3;
}

.preco .parte {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.preco .parte.um {
  align-items: flex-end;
}

.botoes {
  z-index: 3;
}

.parte {
  display: flex;
  position: relative;
  width: 100%;
  z-index: 3;
}
</style>
