<template>
  <section class="criar">
    <div class="quadro">
      <div class="tela">
        <div class="icone">
          <Svgs nome="cursor" />
        </div>
        <p>Seus sites</p>
      </div>

      <div class="plano">
        <img :src="`https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-${storePerfil.usuario?.assinatura?.tipo || 'default'}.svg`" />
        <div class="info">
          <div class="progresso">
            <div class="atual" :style="{ width: `${percentualProgresso || 0}%` }"></div>
          </div>
          <p>
            {{ storePages.pages?.length }} de {{ limitePlano }} {{ textoLimitePlano }}
            <span>{{ percentualProgressoTexto }}%</span>
          </p>
        </div>
      </div>

      <div class="dados">
        <div class="tag on">
          <Svgs nome="check" />
          <p>{{ quantidadeOnline }} online</p>
        </div>
        <div class="tag off">
          <Svgs nome="sair" />
          <p>{{ quantidadeOffline }} offline</p>
        </div>
        <div class="tag www">
          <Svgs nome="dominio" />
          <p>{{ quantidadeDominios }}</p>
        </div>
      </div>

      <button class="upgrade" @click="router.push('/planos')" v-if="limiteAtingido(storePerfil.usuario?.assinatura?.tipo, storePages.pages?.length)">
        <Svgs nome="estrela" />
        <p>Fazer upgrade</p>
      </button>
      <button @click="criarSite()" v-else>
        <Svgs nome="mais" />
        <p>Criar site agora</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStorePerfil, useStoreModal, useStorePages } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storeModal = useStoreModal()
const storePages = useStorePages()
const router = useRouter()

const limitePlano = computed(() => {
  const tipoPlano = storePerfil.usuario?.assinatura?.tipo
  switch (tipoPlano) {
    case 'free':
      return 1
    case 'silver':
      return 3
    case 'gold':
      return 10
    case 'diamond':
      return 50
    case 'unlimited':
      return 1000
    default:
      return 0
  }
})

const planosLimites = {
  free: 1,
  silver: 3,
  gold: 10,
  diamond: 50,
  unlimited: 1000
}

const limiteAtingido = (tipo, totalSites) => {
  const limite = planosLimites[tipo] || 0
  return totalSites > limite
}

function criarSite() {
  storeModal.abrirModal('opcoesCriacao', 'mais', 'Criar site')
}

const formatarQuantidade = (quantidade, singular, plural) => {
  return quantidade === 1 ? singular : plural
}

const textoLimitePlano = computed(() => {
  const pages = storePages.pages || []
  const quantidade = pages.filter((page) => page).length
  return `${formatarQuantidade(quantidade, 'site', 'sites')}`
})

const quantidadeOnline = computed(() => {
  const pages = storePages.pages || []
  const quantidade = pages.filter((page) => page.online).length
  return `${quantidade} ${formatarQuantidade(quantidade, 'site', 'sites')}`
})

const quantidadeOffline = computed(() => {
  const pages = storePages.pages || []
  const quantidade = pages.filter((page) => !page.online).length
  return `${quantidade} ${formatarQuantidade(quantidade, 'site', 'sites')}`
})

const quantidadeDominios = computed(() => {
  const pages = storePages.pages || []
  const quantidade = pages.filter((page) => page.dominioPersonalizado).length
  return `${quantidade} ${formatarQuantidade(quantidade, 'domínio', 'domínios')}`
})

const percentualProgressoTexto = computed(() => {
  const quantidadeCriada = storePages.pages?.length || 0
  const limite = limitePlano.value
  return limite > 0 ? Math.round((quantidadeCriada / limite) * 100) : 0
})

const percentualProgresso = computed(() => {
  const quantidadeCriada = storePages.pages?.length || 0
  const limite = limitePlano.value
  const progresso = limite > 0 ? (quantidadeCriada / limite) * 100 : 0
  return Math.min(Math.round(progresso), 100)
})
</script>

<style scoped>
section.criar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
}

.quadro .tela {
  display: flex;
  align-items: center;
  margin: 0 30px 0 0;
}

.quadro .tela .icone {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
}

.quadro .tela .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}
.quadro .tela p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .plano {
  display: flex;
  align-items: center;
}

.quadro .plano img {
  width: 100%;
  max-width: 30px;
  max-height: 30px;
}

.quadro .plano .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  margin: 0 0 0 10px;
}

.quadro .plano .info .progresso {
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
}

.quadro .plano .info .progresso .atual {
  width: 0%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-branco);
  transition: all 3s;
}

.quadro .plano .info p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
  margin: 10px 0 0 0;
}

.quadro .plano .info p span {
  color: var(--cor-cinza-5);
}

.quadro .dados {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.quadro .dados .tag {
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
}

.quadro .dados .tag p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.quadro .dados .tag svg {
  width: 15px;
  min-width: 15px;
  margin: 0 10px 0 0;
}

.quadro .dados .tag.on svg {
  fill: var(--cor-azul);
}

.quadro .dados .tag.off svg {
  fill: var(--cor-vermelho);
}

.quadro .dados .tag.www svg {
  fill: var(--cor-verde);
}

.quadro button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  border-radius: 10px;
  background-color: var(--cor-azul);
  transition: all 0.3s;
}

.quadro button.upgrade {
  background-color: var(--cor-laranja);
}

.quadro button.upgrade:hover {
  background-color: var(--cor-laranja-escuro);
}

.quadro button:hover {
  background-color: var(--cor-azul-escuro);
}

.quadro button svg {
  width: 15px;
  min-width: 15px;
  margin: 0 10px 0 0;
  fill: var(--cor-branco-fixo);
}

.quadro button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.criar {
    padding: 20px 20px 0 20px;
  }

  .quadro {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
  }

  .quadro .tela {
    width: 50%;
    margin: 0;
  }

  .quadro .tela .icone {
    width: 30px;
    height: 30px;
    margin: 0 8px 0 0;
  }

  .quadro .tela .icone svg {
    width: 15px;
    min-width: 15px;
  }
  .quadro .tela p {
    font-size: var(--f2);
  }

  .quadro .plano {
    justify-content: flex-end;
    width: 50%;
  }

  .quadro .plano img {
    max-width: 20px;
    max-height: 20px;
  }

  .quadro .plano .info {
    flex-direction: row-reverse;
    align-items: center;
    margin: 0;
    width: auto;
  }

  .quadro .plano .info .progresso {
    display: none;
    width: 80px;
    min-width: 80px;
  }

  .quadro .plano .info p {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0 0 10px;
  }

  .quadro .plano .info p span {
    margin: 0 0 0 5px;
  }

  .quadro .dados {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0;
    padding: 20px 0;
    width: 100%;
  }

  .quadro .dados .tag {
    margin: 0;
  }

  .quadro .dados .tag p {
    font-size: var(--f0);
  }

  .quadro .dados .tag svg {
    width: 11px;
    min-width: 11px;
    margin: 0 8px 0 0;
  }

  .quadro button {
    padding: 12px 30px;
    width: 100%;
  }

  .quadro button svg {
    width: 13px;
    min-width: 13px;
    margin: 0 8px 0 0;
  }
}
</style>
