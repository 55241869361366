<template>
  <Modal nome="configurarDominio">
    <!-- plano free -->
    <div class="etapa free" v-if="state.etapaZero">
      <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-free.svg" />
      <h3>Seu próprio domínio</h3>
      <p>Deixe seu site ainda mais único com domínio próprio. Além do unic.page você poderá colocar qualquer domínio atrelado a este site em questão de segundos. Faça seu upgrade e libere acesso à todas as funcionalidades da UnicPages.</p>
      <button @click="router.push('/planos')">
        <Svgs nome="estrela" />
        Fazer Upgrade
      </button>
    </div>
    <!-- Primeira etapa -->
    <div class="etapa primeira" v-if="state.etapaUm">
      <div class="itens">
        <div class="info">
          <h2>Escolha como deseja configurar</h2>
          <p>Você pode usar o seu domínio principal, subdomínio ou domínio com rota, escolha abaixo qual deseja configurar</p>
        </div>
        <!-- Opções -->
        <button class="botao um" @click="avancarDoisDominio()">
          <div class="icone">
            <Svgs nome="dominio" />
          </div>
          <div class="texto">
            <h3>Domínio Principal</h3>
            <p>seudominio.com</p>
          </div>
          <Svgs class="setinha" nome="setinha" />
        </button>
        <!-- Opções -->
        <button class="botao um" @click="avancarDoisSubdominio()">
          <div class="icone">
            <Svgs nome="link" />
          </div>
          <div class="texto">
            <h3>Subdomínio</h3>
            <p>sub.seudominio.com</p>
          </div>
          <Svgs class="setinha" nome="setinha" />
        </button>
        <!-- Opções -->
        <button class="botao um" @click="avancarDoisRota()">
          <div class="icone">
            <Svgs nome="cursor" />
          </div>
          <div class="texto">
            <h3>Domínio com rota</h3>
            <p>seudominio.com/rota</p>
          </div>
          <Svgs class="setinha" nome="setinha" />
        </button>
      </div>
    </div>

    <!-- Segunda etapa -->
    <div class="etapa segunda" v-if="state.etapaDoisDominio">
      <a target="_blank" href="https://arquivos.unicpages.com/imagens/app/tutoriais/tutorial-dominio.pdf">
        <Svgs nome="duvida" />
        <p>Como configurar domínio</p>
      </a>
      <label>
        Digite seu domínio
        <span>*</span>
      </label>
      <input type="url" v-model="state.dominioDefinido" @input="validarDominio($event, 'dominioDefinido')" placeholder="ex: unicpages.com" autocomplete="off" spellcheck="false" />

      <div class="quadro">
        <div class="icone">
          <Svgs nome="cadeado" />
        </div>
        <div class="texto">
          <h3>HTTPS</h3>
          <p>Não é necessário digitar o domínio com https pois o Certificado SSL já estará ativo automaticamente</p>
        </div>
      </div>
      <div class="quadro">
        <div class="icone">
          <Svgs nome="chave" />
        </div>
        <div class="texto">
          <h3>Tenha acesso</h3>
          <p>Só adicione um domínio que você tenha acesso. Será necessário configurar ele onde comprou.</p>
        </div>
      </div>
      <div class="quadro">
        <div class="icone">
          <Svgs nome="atencao" />
        </div>
        <div class="texto">
          <h3>WWW</h3>
          <p>Não é necessário cadastrar www. mas caso queira que o www. funcione crie como subdomínio na etapa anterior</p>
        </div>
      </div>
      <BotaoSalvar @click="avancarTres()" :nome="`Configurar ${state.dominioDefinido}`" />
    </div>

    <!-- Segunda etapa SUBDOMINIO -->
    <div class="etapa segunda" v-if="state.etapaDoisSubdominio">
      <a target="_blank" href="https://arquivos.unicpages.com/imagens/app/tutoriais/tutorial-dominio.pdf">
        <Svgs nome="duvida" />
        <p>Como configurar domínio</p>
      </a>
      <label>
        Digite seu subdomínio e o domínio principal
        <span>*</span>
      </label>
      <div class="inputs">
        <input type="url" class="dois" @input="validarDominio($event, 'subdominioDefinido')" v-model="state.subdominioDefinido" placeholder="subdominio" autocomplete="off" spellcheck="false" />
        <p>.</p>
        <input type="url" class="um" @input="validarDominio($event, 'dominioDefinido')" v-model="state.dominioDefinido" placeholder="unicpages.com" autocomplete="off" spellcheck="false" />
      </div>

      <div class="quadro">
        <div class="icone">
          <Svgs nome="cursor" />
        </div>
        <div class="texto">
          <h3>Subdomínio</h3>
          <p>Digite o subdomínio e domínio corretamente. subdominio.seudominio.com, por ex: lp.unicpages.com</p>
        </div>
      </div>
      <div class="quadro">
        <div class="icone">
          <Svgs nome="atencao" />
        </div>
        <div class="texto">
          <h3>WWW</h3>
          <p>Não é necessário usar www. em subdomínio, somente caso queira que os visitantes usem www. para acessar seu domínio</p>
        </div>
      </div>
      <div class="quadro">
        <div class="icone">
          <Svgs nome="chave" />
        </div>
        <div class="texto">
          <h3>Tenha acesso</h3>
          <p>Só configure domínios que você tenha acesso. Será necessário configurar o domínio onde comprou.</p>
        </div>
      </div>
      <BotaoSalvar @click="avancarTres()" :nome="`Configurar ${state.subdominioDefinido}${state.dominioDefinido ? '.' + state.dominioDefinido : ''}`" />
    </div>

    <!-- Segunda etapa -->
    <div class="etapa segunda" v-if="state.etapaDoisRota">
      <a target="_blank" href="https://arquivos.unicpages.com/imagens/app/tutoriais/tutorial-dominio.pdf">
        <Svgs nome="duvida" />
        <p>Como configurar domínio</p>
      </a>
      <label>
        Digite seu domínio e a rota dele
        <span>*</span>
      </label>
      <div class="inputs">
        <input type="url" class="um" v-model="state.dominioDefinido" placeholder="unicpages.com" autocomplete="off" spellcheck="false" />
        <p>/</p>
        <input type="url" class="dois" v-model="state.rotaDefinida" placeholder="inicio" autocomplete="off" spellcheck="false" />
      </div>

      <div class="quadro">
        <div class="icone">
          <Svgs nome="cursor" />
        </div>
        <div class="texto">
          <h3>Sua rota</h3>
          <p>A rota deve ser clara, sem caracteres especiais. Evite rotas muito longas pois pode atrapalhar na confiança do usuário</p>
        </div>
      </div>
      <div class="quadro">
        <div class="icone">
          <Svgs nome="cadeado" />
        </div>
        <div class="texto">
          <h3>HTTPS e WWW</h3>
          <p>Não é necessário digitar https no domínio pois o certificado já está ativo. Caso queira com www cadastre como subdomínio</p>
        </div>
      </div>
      <div class="quadro">
        <div class="icone">
          <Svgs nome="atencao" />
        </div>
        <div class="texto">
          <h3>Domínio Principal</h3>
          <p>Rotas só funcionam com o domínio principal, subdominio não irá funcionar sua rota. Ex: lp.unicpages.com/rota</p>
        </div>
      </div>
      <BotaoSalvar @click="avancarTres()" :nome="`Configurar ${state.dominioDefinido}${state.rotaDefinida ? '/' + state.rotaDefinida : ''}`" />
    </div>
    <!-- Terceira etapa -->
    <div class="etapa terceira" v-if="state.etapaTres">
      <div class="atual">
        <h3>{{ state.subdominioDefinido ? state.subdominioDefinido + '.' : '' || '' }}{{ state.dominioDefinido }}{{ state.rotaDefinida ? '/' + state.rotaDefinida : '' || '' }}</h3>
        <button @click="voltarEtapa()">
          <Svgs nome="editar" />
          <p>Alterar</p>
        </button>
      </div>
      <div class="tutorial">
        <div class="quadro">
          <Svgs class="icone" nome="dominio" />
          <h2>Vá até o local onde foi comprado seu domínio e crie um registro DNS com os dados do quadro ao lado</h2>
          <a target="_blank" href="https://arquivos.unicpages.com/imagens/app/tutoriais/tutorial-dominio.pdf">
            <Svgs nome="duvida" />
            Como configurar
          </a>
        </div>
        <div class="quadro">
          <div class="linha">
            <p>Tipo</p>
            <h3>A</h3>
          </div>
          <div class="linha">
            <p>Nome</p>
            <h3>{{ state.subdominioDefinido || '@' }}</h3>
          </div>
          <div class="linha">
            <p>Destino</p>
            <h3 @click="copiarIp()">
              157.245.90.32
              <Svgs nome="duplicar" />
            </h3>
          </div>
          <div class="linha">
            <p>TTL</p>
            <h3>O menor possível</h3>
          </div>
        </div>
      </div>

      <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
        <span>
          <Svgs nome="check" />
        </span>
        <p>Pronto, fiz o que foi solicitado acima</p>
      </div>

      <BotaoSalvar nome="Salvar meu domínio" :disabled="state.loading" @click="verificarDNS()" />
    </div>

    <!-- Domínio configurado -->
    <div class="etapa configurado" v-if="state.etapaCadastrado">
      <div class="atual" @click="irParaLink()">
        <div class="icone">
          <Svgs nome="link" />
        </div>
        <h3>{{ state.dominioDefinido }}{{ state.rotaDefinida ? '/' + state.rotaDefinida : '' || '' }}</h3>
      </div>
      <div class="tutorial">
        <div class="quadro">
          <Svgs class="icone" nome="dominio" />
          <h2>Vá até o local onde foi comprado seu domínio e crie um registro DNS com os dados do quadro ao lado</h2>
          <a target="_blank" href="https://arquivos.unicpages.com/imagens/app/tutoriais/tutorial-dominio.pdf">
            <Svgs nome="duvida" />
            Como configurar
          </a>
        </div>
        <div class="quadro">
          <div class="linha">
            <p>Tipo</p>
            <h3>A</h3>
          </div>
          <div class="linha">
            <p>Nome</p>
            <h3>{{ state.subdominio || '@' }}</h3>
          </div>
          <div class="linha">
            <p>Destino</p>
            <h3 @click="copiarIp()">157.245.90.32</h3>
          </div>
          <div class="linha">
            <p>TTL</p>
            <h3>O menor possível</h3>
          </div>
        </div>
      </div>

      <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
        <span>
          <Svgs nome="check" />
        </span>
        <p>Sim, quero excluir esse domínio</p>
      </div>

      <BotaoExcluir nome="Excluir Domínio" :disabled="state.loading" @click="removerDominio()" />
    </div>
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue'
import { useStoreDominios, useStorePages, useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import BotaoExcluir from '@components/global/elementos/BotaoExcluir.vue'

const emitter = inject('emitter')
const storeDominios = useStoreDominios()
const storePages = useStorePages()
const storePerfil = useStorePerfil()
const router = useRouter()

const state = reactive({
  etapaZero: false,
  etapaUm: true,
  etapaDoisDominio: false,
  etapaDoisSubdominio: false,
  etapaDoisRota: false,
  etapaTres: false,
  etapaCadastrado: false,
  loading: false,
  confirmar: false,
  dominioDefinido: '',
  subdominioDefinido: '',
  rotaDefinida: '',
  subdominio: ''
})

function validarDominio(event, campo) {
  const valor = event.target.value

  const valorFiltrado = valor.replace(/[^a-z0-9.-]/gi, '').toLowerCase()

  state[campo] = valorFiltrado
}

function avancarDoisDominio() {
  state.etapaUm = false
  state.etapaDoisDominio = true
}

function avancarDoisRota() {
  state.etapaUm = false
  state.etapaDoisRota = true
}

function avancarDoisSubdominio() {
  state.etapaUm = false
  state.etapaDoisSubdominio = true
}

function voltarEtapa() {
  if (state.rotaDefinida) {
    state.etapaTres = false
    state.etapaDoisRota = true
  } else if (state.subdominioDefinido) {
    state.etapaTres = false
    state.etapaDoisSubdominio = true
  } else {
    state.etapaTres = false
    state.etapaDoisDominio = true
  }
}

function avancarTres() {
  if (!state.dominioDefinido) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Preencha o domínio que deseja' })
  } else {
    state.etapaDoisDominio = false
    state.etapaDoisSubdominio = false
    state.etapaDoisRota = false
    state.etapaTres = true
  }
}

function irParaLink() {
  let url = ''

  if (state.subdominioDefinido) {
    url += `${state.subdominioDefinido}.`
  }

  if (state.dominioDefinido) {
    url += state.dominioDefinido
  }

  if (state.rotaDefinida) {
    url += `/${state.rotaDefinida}`
  }

  window.open(`https://${url}`, '_blank')
}

async function copiarIp() {
  const url = `157.245.90.32`
  await navigator.clipboard.writeText(url)
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Destino copiado!' })
}

async function verificarDNS() {
  if (!state.confirmar) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Confirme que fez as configurações' })
    return
  }
  state.loading = true

  const dominioOK = await storeDominios.validarDominioDNS(state.subdominioDefinido ? `${state.subdominioDefinido}.${state.dominioDefinido}` : state.dominioDefinido)
  state.loading = false
  if (!dominioOK) return

  state.loading = false
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Domínio configurado!' })

  const dominioFormatado = state.subdominioDefinido ? `${state.subdominioDefinido}.${state.dominioDefinido}` : state.dominioDefinido

  const payload = {
    dominioPersonalizado: dominioFormatado,
    dominioRota: state.rotaDefinida,
    idPage: storePages.page?._id
  }
  const dominioAlteradoOk = await storePages.alterarDominio(payload)
  if (!dominioAlteradoOk) return

  window.location.reload()
}

async function removerDominio() {
  if (!state.confirmar) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Marque a confirmação de exclusão' })
  } else {
    const payload = { idPage: storePages.page?._id }
    const dominioRemovidoOk = await storePages.removerDominio(payload)
    if (!dominioRemovidoOk) return

    window.location.reload()
  }
}

function limparCampos() {
  state.dominioDefinido = ''
  state.subdominioDefinido = ''
  state.rotaDefinida = ''
  state.etapaUm = true
  state.etapaDoisDominio = false
  state.etapaDoisSubdominio = false
  state.etapaDoisRota = false
  state.etapaTres = false
  state.etapaCadastrado = false
  state.loading = false
  state.confirmar = false
}

function verificarDominio() {
  if (!storePages.page?.dominioPersonalizado) {
    state.etapaUm = true
    verificarAssinatura()
  } else {
    state.dominioDefinido = storePages.page?.dominioPersonalizado
    state.rotaDefinida = storePages.page?.dominioRota
    state.etapaUm = false
    state.etapaCadastrado = true
  }
}

function verificarAssinatura() {
  if (storePerfil.usuario?.assinatura?.tipo === 'free') {
    state.etapaUm = false
    state.etapaZero = true
  }
}

onMounted(() => {
  emitter.on('carregarCampos', verificarDominio)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.etapa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  animation: fadeIn 0.3s linear;
}

.free {
  align-items: center;
  padding: 20px 0;
}

.free img {
  width: 100%;
  max-width: 50px;
  margin: 0 0 20px 0;
}

.free h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  text-align: center;
}

.free p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  text-align: center;
  line-height: 1.5;
  width: 100%;
  max-width: 400px;
  margin: 5px 0 10px 0;
}

.free button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-align: center;
  background-color: var(--cor-laranja);
  padding: 15px 30px;
  border-radius: 10px;
  transition: all 0.3s;
}

.free button:hover {
  background-color: var(--cor-laranja-escuro);
}

.free button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.primeira .itens {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.opcoes {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.primeira .info h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.primeira .info p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 10px 0 0 0;
}

.primeira .itens .botao {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  position: relative;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.primeira .itens .botao:hover {
  background-color: var(--cor-cinza-4);
}

.primeira .itens .botao .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 20px;
  border: 2px solid var(--cor-azul);
  margin: 0 20px 0 0;
}

.primeira .itens .botao .icone svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-branco);
}

.primeira .itens .botao .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.primeira .itens .botao .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.primeira .itens .botao .texto p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul-escuro);
  padding: 4px 10px;
  border-radius: 10px;
}

.primeira .itens .botao .texto p span {
  font-family: var(--bold);
  color: var(--cor-azul);
}

.primeira .itens .botao svg.setinha {
  position: absolute;
  right: 20px;
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  transform: rotate(-90deg);
}

.segunda a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s;
}

.segunda a:hover {
  opacity: 0.6;
}

.segunda a svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

.segunda label {
  margin: 0 0 10px 0;
}

.segunda .inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.segunda .inputs p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-cinza-5);
  margin: 0 5px;
}

.segunda .inputs input.um {
  width: calc(55% - 5px);
}

.segunda .inputs input.dois {
  width: calc(45% - 5px);
}

.segunda .quadro {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  margin: 20px 0 0 0;
}

.segunda .quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 20px;
  border: 2px solid var(--cor-azul);
  margin: 0 20px 0 0;
}

.segunda .quadro .icone svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-branco);
}

.segunda .quadro .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.segunda .quadro .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.segunda .quadro .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.segunda .quadro .texto p span {
  font-family: var(--bold);
  color: var(--cor-azul);
}

.segunda button {
  margin: 20px 0 0 0;
}

.terceira .atual {
  display: flex;
  width: 100%;
}

.terceira .atual h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul);
  padding: 15px;
  background-color: transparent;
  border: 2px solid var(--cor-azul);
  border-radius: 10px;
  width: 100%;
}

.terceira .atual button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  padding: 15px 20px;
  border-radius: 10px;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.terceira .atual button:hover {
  background-color: var(--cor-cinza-4);
}

.terceira .atual button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.terceira .tutorial {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 20px 0;
}

.terceira .tutorial .quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(50% - 6px);
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  padding: 30px;
}

.terceira .tutorial .quadro svg.icone {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
}

.terceira .tutorial .quadro h2 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 20px 0 0 0;
}

.terceira .tutorial .quadro a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-laranja);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.terceira .tutorial .quadro a:hover {
  opacity: 0.6;
}

.terceira .tutorial .quadro a svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-laranja);
  margin: 0 10px 0 0;
}

.terceira .tutorial .quadro .linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.terceira .tutorial .quadro .linha p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.terceira .tutorial .quadro .linha h3 {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.terceira .tutorial .quadro .linha h3 svg {
  width: 14px;
  min-width: 14px;
  fill: var(--cor-branco);
  margin: 0 0 0 5px;
  transition: all 0.3s;
}

.terceira .tutorial .quadro .linha h3:hover svg {
  fill: var(--cor-azul);
}

.configurado .atual {
  display: flex;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
}

.configurado .atual:hover {
  opacity: 0.7;
}

.configurado .atual .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  min-width: 50px;
  border: 2px solid var(--cor-azul);
  border-radius: 10px;
}

.configurado .atual .icone svg {
  width: 16px;
  min-width: 16px;
  fill: var(--cor-azul);
}

.configurado .atual h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul);
  padding: 15px;
  background-color: transparent;
  border: 2px solid var(--cor-azul);
  border-radius: 10px;
  width: 100%;
  margin: 0 0 0 5px;
}

.configurado .tutorial {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 20px 0;
}

.configurado .tutorial .quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(50% - 10px);
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  padding: 30px;
}

.configurado .tutorial .quadro svg.icone {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
}

.configurado .tutorial .quadro h2 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 20px 0 0 0;
}

.configurado .tutorial .quadro a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-laranja);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.configurado .tutorial .quadro a:hover {
  opacity: 0.6;
}

.configurado .tutorial .quadro a svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-laranja);
  margin: 0 10px 0 0;
}

.configurado .tutorial .quadro .linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.configurado .tutorial .quadro .linha p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.configurado .tutorial .quadro .linha h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .terceira .tutorial {
    flex-direction: column;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .terceira .tutorial .quadro {
    width: 100%;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .terceira .tutorial .quadro .linha {
    padding: 10px 0;
  }
  .configurado .tutorial {
    flex-direction: column;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .configurado .tutorial .quadro {
    width: 100%;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .configurado .tutorial .quadro .linha {
    padding: 10px 0;
  }
}
</style>
