<template>
  <div class="site">
    <AutoLoad />
    <AutoSave />
    <div class="componente" v-for="item in state.componentesDesktop" :key="item.id">
      <component :is="componentMapDesktop[item.nome]" :id="item.id" />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, reactive, onMounted, shallowRef, inject } from 'vue'
import { useStoreCriar, useStorePages } from '@stores'
import { useRouter } from 'vue-router'

import AutoSave from '@/components/criar/partials/AutoSave.vue'
import AutoLoad from '@/components/criar/partials/AutoLoad.vue'

const emitter = inject('emitter')
const router = useRouter()
const storeCriar = useStoreCriar()
const storePages = useStorePages()

const state = reactive({
  componentesDesktop: []
})

async function carregarPage() {
  const hotlink = router.currentRoute.value.params.hotlinkTemplate
  await storePages.receberPorHotlink(hotlink)
}

const componentMapDesktop = shallowRef({
  Nav1Desktop: importComponent('nav', '1'),
  Nav2Desktop: importComponent('nav', '2'),
  Nav3Desktop: importComponent('nav', '3'),
  Nav4Desktop: importComponent('nav', '4'),
  Topo1Desktop: importComponent('topo', '1'),
  Topo2Desktop: importComponent('topo', '2'),
  Topo3Desktop: importComponent('topo', '3'),
  Topo4Desktop: importComponent('topo', '4'),
  Topo5Desktop: importComponent('topo', '5'),
  Quadro1Desktop: importComponent('quadro', '1'),
  Quadro2Desktop: importComponent('quadro', '2'),
  Quadro3Desktop: importComponent('quadro', '3'),
  Duvida1Desktop: importComponent('duvida', '1'),
  Formulario1Desktop: importComponent('formulario', '1'),
  Formulario2Desktop: importComponent('formulario', '2'),
  Formulario3Desktop: importComponent('formulario', '3'),
  Formulario4Desktop: importComponent('formulario', '4'),
  Formulario5Desktop: importComponent('formulario', '5'),
  Formulario6Desktop: importComponent('formulario', '6'),
  Formulario7Desktop: importComponent('formulario', '7'),
  Plano1Desktop: importComponent('plano', '1'),
  Plano2Desktop: importComponent('plano', '2'),
  Plano3Desktop: importComponent('plano', '3'),
  Slide1Desktop: importComponent('slide', '1'),
  Footer1Desktop: importComponent('footer', '1'),
  Footer2Desktop: importComponent('footer', '2'),
  Html1Desktop: importComponent('html', '1'),
  Html2Desktop: importComponent('html', '2')
})

function importComponent(section, version) {
  const sectionCapitalized = section.charAt(0).toUpperCase() + section.slice(1)
  return defineAsyncComponent(() => import(`../secoes/padrao/${section}/${version}/versaoDesktop/${sectionCapitalized}.vue`))
}

function renderizarComponentes() {
  state.componentesDesktop = storeCriar.componentes.desktop
}

onMounted(() => {
  carregarPage()
  renderizarComponentes()
  emitter.on('renderizarComponentesTela', renderizarComponentes)
})
</script>
