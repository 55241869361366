<template>
  <section class="lista">
    <div class="itens">
      <div class="item" v-for="template in ordenadosPorData" :key="template._id" @click="storeModal.abrirModalEdicao('verTemplate', 'template', 'Visualizar Template', template)">
        <div class="conteudo">
          <div class="info">
            <h3>{{ template.nome }}</h3>
            <p>{{ categoriasMapeadas[template.categoria] || template.categoria }}</p>
          </div>
          <div class="capa">
            <div class="tela">
              <img :src="template.imagemCapa" />
            </div>
            <div class="fundo" :style="`background-image: url('${template.imagemCapa || ''}')`"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, computed, watchEffect, ref, inject } from 'vue'
import { useStoreTemplates, useStoreModal } from '@stores'

const storeModal = useStoreModal()
const storeTemplates = useStoreTemplates()
const termoBusca = ref('')
const templatesPorCategoria = ref({})
const emitter = inject('emitter')

const categoriasMapeadas = {
  'academia': 'Academia',
  'academia-de-luta': 'Academia de Luta',
  'acai': 'Açaí',
  'acougue': 'Açougue',
  'assistencia-tecnica': 'Assistência Técnica',
  'cabeleireiro': 'Cabeleireiro',
  'consultorio-medico': 'Consultório Médico',
  'contabilidade': 'Contabilidade',
  'conveniencia': 'Conveniência',
  'dentista': 'Dentista',
  'escola-de-idiomas': 'Escola de Idiomas',
  'escola-e-faculdade': 'Escola e Faculdade',
  'escritorio-advocacia': 'Escritório Advocacia',
  'estetica-automotiva': 'Estética Automotiva',
  'farmacia': 'Farmácia',
  'hamburgueria': 'Hamburgueria',
  'imobiliaria': 'Imobiliária',
  'link-de-bio': 'Link de Bio',
  'loja-de-moveis': 'Loja de Móveis',
  'loja-de-roupa': 'Loja de Roupa',
  'loja-de-tintas': 'Loja de Tintas',
  'maquiadora': 'Maquiadora',
  'marketing-digital': 'Marketing Digital',
  'materiais-de-construcao': 'Materiais de Construção',
  'mercearia': 'Mercearia',
  'mecanica': 'Mecânica',
  'padaria': 'Padaria',
  'papelaria': 'Papelaria',
  'pet-shop': 'Pet Shop',
  'portfolio': 'Portfólio',
  'sorveteria': 'Sorveteria'
}

function carregarTemplates() {
  storeTemplates.receberTodos()
  filtrarTemplates()
}

const ordenadosPorData = computed(() => {
  return storeTemplates.templates.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
})

function filtrarTemplates() {
  const termo = termoBusca.value.trim().toLowerCase()
  const agrupadosPorCategoria = {}

  const templatesFiltrados =
    termo === ''
      ? storeTemplates.templates.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
      : storeTemplates.templates
          .filter((template) => {
            return template.nome?.toLowerCase().includes(termo) || template.hotlink.toLowerCase().includes(termo) || template.tags?.some((tag) => tag.toLowerCase().includes(termo))
          })
          .sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))

  templatesFiltrados.forEach((template) => {
    if (!agrupadosPorCategoria[template.categoria]) {
      agrupadosPorCategoria[template.categoria] = []
    }
    agrupadosPorCategoria[template.categoria].push(template)
  })

  templatesPorCategoria.value = agrupadosPorCategoria
}

watchEffect(() => {
  filtrarTemplates()
})

onMounted(() => {
  carregarTemplates()
  emitter.on('carregarTemplates', carregarTemplates())
})
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0 0 0;
  min-height: 100dvh;
}

.quadro {
  display: flex;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.quadro .conteudo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
}

.quadro .tela {
  display: flex;
  align-items: center;
  margin: 0 30px 0 0;
}

.quadro .tela .icone {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
}

.quadro .tela .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}
.quadro .tela p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .plano {
  display: flex;
  align-items: center;
}

.quadro .plano img {
  width: 100%;
  max-width: 30px;
  max-height: 30px;
}

.quadro .plano .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  margin: 0 0 0 10px;
}

.quadro .plano .info .progresso {
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
}

.quadro .plano .info .progresso .atual {
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-branco);
  transition: all 3s;
}

.quadro .plano .info p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
  margin: 10px 0 0 0;
}

.quadro .plano .info p span {
  color: var(--cor-cinza-5);
}

.quadro .input {
  max-width: 500px;
  margin-left: auto;
}

.quadro .input input {
  background-color: var(--cor-cinza-3);
}

.itens {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 5px 0 20px;
  transition: all 0.3s;
}

.itens::-webkit-scrollbar {
  display: none;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(33.33% - 15px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  margin: 0 15px 20px 0;
  cursor: pointer;
}

.item .conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .info h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item .info p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.item .capa {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  width: 100%;
}

.item .capa .fundo {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  border-radius: 0 0 10px 10px;
}

.item .capa .tela {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding: 50px 50px 0 50px;
  border-radius: 0 0 10px 10px;
  z-index: 2;
  backdrop-filter: blur(30px);
  transform: scale(1) translateY(1px);
}

.item .capa .tela img {
  width: 100%;
  border-top: 10px solid var(--cor-cinza-2);
  border-left: 10px solid var(--cor-cinza-2);
  border-right: 10px solid var(--cor-cinza-2);
  transition: all 0.3s;
}

.item:hover .capa .tela img {
  transform: scale(0.95) translateY(15px);
  border-top: 10px solid var(--cor-azul);
  border-left: 10px solid var(--cor-azul);
  border-right: 10px solid var(--cor-azul);
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.lista {
    display: flex;
    flex-direction: column;
  }

  button.seta {
    display: none;
  }

  .quadro .conteudo {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
  }

  .quadro .tela {
    width: 50%;
    margin: 0;
  }

  .quadro .tela .icone {
    width: 30px;
    height: 30px;
    margin: 0 8px 0 0;
  }

  .quadro .tela .icone svg {
    width: 15px;
    min-width: 15px;
  }
  .quadro .tela p {
    font-size: var(--f2);
  }

  .quadro .plano {
    justify-content: flex-end;
    width: 50%;
  }

  .quadro .plano img {
    max-width: 15px;
    max-height: 15px;
  }

  .quadro .plano .info {
    flex-direction: row-reverse;
    align-items: center;
    margin: 0;
    width: auto;
  }

  .quadro .plano .info .progresso {
    display: none;
    width: 80px;
    min-width: 80px;
  }

  .quadro .plano .info p {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0 0 5px;
  }

  .quadro .plano .info p span {
    margin: 0 0 0 5px;
  }

  .quadro .input {
    max-width: 100%;
    margin: 15px 0 0 0;
  }

  .itens {
    padding: 0 20px;
  }

  .item {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .item .capa .tela {
    padding: 30px 30px 0 30px;
  }
}
</style>
