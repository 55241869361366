<template>
  <section class="tela" ref="tela" @mouseover="removeClass">
    <div class="botoes">
      <button class="botao" @click="zoomIn()">
        <Svgs nome="zoom-in" />
        <p>Aumentar</p>
      </button>
      <button class="botao" @click="zoomOut()" :disabled="state.botaoZoomOut">
        <Svgs nome="zoom-out" />
        <p>Diminuir</p>
      </button>
      <button class="botao" @click="centralizarTela()">
        <Svgs nome="centralizar" />
        <p>Centralizar</p>
      </button>
      <button class="botao" @click="abrirModalReplicar()">
        <Svgs nome="mobile" />
        <p>Replicar para Mobile</p>
      </button>
      <button class="botao" @click="alterarTema()">
        <Svgs nome="tema" />
        <p>{{ textoTema() }}</p>
      </button>
      <a class="botao" target="_blank" :href="`https://unic.page/${storePages.page?.hotlink}`">
        <Svgs nome="link" />
        <p>Prévia</p>
      </a>
      <div class="botao cor">
        <ColorPicker class="flat" :valor="state.corFundo" :onAtualizarCor="atualizarCorSelecionada" />
        <p>Fundo</p>
      </div>
    </div>

    <div class="base" v-if="acessoDesktop">
      <VueDraggableResizable class="cor" :x="0" :y="0" :w="1" :h="1">
        <div class="sites" :style="`background-color: #${state.corFundo}`">
          <div class="conteudo" :style="{ transform: `scale(${zoomLevel})` }">
            <div class="site desktop">
              <div class="componente" v-for="item in state.componentesDesktop" :key="item.id">
                <component :is="componentMapDesktop[item.nome]" :id="item.id" />
              </div>
              <button class="nova" @click="abrirSecoesDesktop()">
                <Svgs nome="mais" />
                <p>Nova seção</p>
              </button>
            </div>
            <div class="site mobile">
              <div class="componente" v-for="item in state.componentesMobile" :key="item.id">
                <component :is="componentMapMobile[item.nome]" :id="item.id" />
              </div>
              <button class="nova" @click="abrirSecoesMobile()">
                <Svgs nome="mais" />
                <p>Nova seção</p>
              </button>
            </div>
          </div>
        </div>
      </VueDraggableResizable>
    </div>

    <div class="base" v-else>
      <div class="sites" :style="`background-color: #${state.corFundo}`">
        <div class="conteudo" :style="{ transform: `scale(${zoomLevel})` }">
          <div class="site desktop">
            <div class="componente" v-for="item in state.componentesDesktop" :key="item.id">
              <component :is="componentMapDesktop[item.nome]" :id="item.id" />
            </div>
            <button class="nova" @click="abrirSecoesDesktop()">
              <Svgs nome="mais" />
              <p>Nova seção</p>
            </button>
          </div>
          <div class="site mobile">
            <div class="componente" v-for="item in state.componentesMobile" :key="item.id">
              <component :is="componentMapMobile[item.nome]" :id="item.id" />
            </div>
            <button class="nova" @click="abrirSecoesMobile()">
              <Svgs nome="mais" />
              <p>Nova seção</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineAsyncComponent, ref, reactive, onMounted, shallowRef, onBeforeUnmount, inject } from 'vue'
import { useStoreCriar, useStorePages, useStoreModal } from '@stores'
import ColorPicker from '@/components/global/elementos/ColorPicker.vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeCriar = useStoreCriar()
const storePages = useStorePages()
const storeModal = useStoreModal()

const tela = ref(null)
const acessoDesktop = ref(window.innerWidth >= 1024)

const state = reactive({
  tema: JSON.parse(localStorage.getItem('personalizacao-unicpages'))?.tema,
  componentesDesktop: [],
  componentesMobile: [],
  botaoZoomOut: false,
  corFundo: ''
})

const componentMapDesktop = shallowRef({
  Nav1Desktop: importComponent('nav', '1', 'desktop'),
  Nav2Desktop: importComponent('nav', '2', 'desktop'),
  Nav3Desktop: importComponent('nav', '3', 'desktop'),
  Nav4Desktop: importComponent('nav', '4', 'desktop'),
  Topo1Desktop: importComponent('topo', '1', 'desktop'),
  Topo2Desktop: importComponent('topo', '2', 'desktop'),
  Topo3Desktop: importComponent('topo', '3', 'desktop'),
  Topo4Desktop: importComponent('topo', '4', 'desktop'),
  Topo5Desktop: importComponent('topo', '5', 'desktop'),
  Quadro1Desktop: importComponent('quadro', '1', 'desktop'),
  Quadro2Desktop: importComponent('quadro', '2', 'desktop'),
  Quadro3Desktop: importComponent('quadro', '3', 'desktop'),
  Duvida1Desktop: importComponent('duvida', '1', 'desktop'),
  Formulario1Desktop: importComponent('formulario', '1', 'desktop'),
  Formulario2Desktop: importComponent('formulario', '2', 'desktop'),
  Formulario3Desktop: importComponent('formulario', '3', 'desktop'),
  Formulario4Desktop: importComponent('formulario', '4', 'desktop'),
  Formulario5Desktop: importComponent('formulario', '5', 'desktop'),
  Formulario6Desktop: importComponent('formulario', '6', 'desktop'),
  Formulario7Desktop: importComponent('formulario', '7', 'desktop'),
  Plano1Desktop: importComponent('plano', '1', 'desktop'),
  Plano2Desktop: importComponent('plano', '2', 'desktop'),
  Plano3Desktop: importComponent('plano', '3', 'desktop'),
  Slide1Desktop: importComponent('slide', '1', 'desktop'),
  Footer1Desktop: importComponent('footer', '1', 'desktop'),
  Footer2Desktop: importComponent('footer', '2', 'desktop'),
  Html1Desktop: importComponent('html', '1', 'desktop'),
  Html2Desktop: importComponent('html', '2', 'desktop')
})

const componentMapMobile = shallowRef({
  Nav1Mobile: importComponent('nav', '1', 'mobile'),
  Nav2Mobile: importComponent('nav', '2', 'mobile'),
  Nav3Mobile: importComponent('nav', '3', 'mobile'),
  Nav4Mobile: importComponent('nav', '4', 'mobile'),
  Topo1Mobile: importComponent('topo', '1', 'mobile'),
  Topo2Mobile: importComponent('topo', '2', 'mobile'),
  Topo3Mobile: importComponent('topo', '3', 'mobile'),
  Topo4Mobile: importComponent('topo', '4', 'mobile'),
  Topo5Mobile: importComponent('topo', '5', 'mobile'),
  Quadro1Mobile: importComponent('quadro', '1', 'mobile'),
  Quadro2Mobile: importComponent('quadro', '2', 'mobile'),
  Quadro3Mobile: importComponent('quadro', '3', 'mobile'),
  Duvida1Mobile: importComponent('duvida', '1', 'mobile'),
  Formulario1Mobile: importComponent('formulario', '1', 'mobile'),
  Formulario2Mobile: importComponent('formulario', '2', 'mobile'),
  Formulario3Mobile: importComponent('formulario', '3', 'mobile'),
  Formulario4Mobile: importComponent('formulario', '4', 'mobile'),
  Formulario5Mobile: importComponent('formulario', '5', 'mobile'),
  Formulario6Mobile: importComponent('formulario', '6', 'mobile'),
  Formulario7Mobile: importComponent('formulario', '7', 'mobile'),
  Plano1Mobile: importComponent('plano', '1', 'mobile'),
  Plano2Mobile: importComponent('plano', '2', 'mobile'),
  Plano3Mobile: importComponent('plano', '3', 'mobile'),
  Slide1Mobile: importComponent('slide', '1', 'mobile'),
  Footer1Mobile: importComponent('footer', '1', 'mobile'),
  Footer2Mobile: importComponent('footer', '2', 'mobile'),
  Html1Mobile: importComponent('html', '1', 'mobile'),
  Html2Mobile: importComponent('html', '2', 'mobile')
})

function importComponent(section, version, type) {
  const sectionCapitalized = section.charAt(0).toUpperCase() + section.slice(1)
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1)
  return defineAsyncComponent(() => import(`../secoes/padrao/${section}/${version}/versao${typeCapitalized}/${sectionCapitalized}.vue`))
}

function atualizarCorSelecionada(novaCor) {
  state.corFundo = novaCor
  localStorage.setItem('corFundoUnicPages', novaCor)
}

function textoTema() {
  if (state.tema === 'claro') return 'Escuro'
  else if (state.tema === 'escuro') return 'Claro'
  else return 'Tema'
}

function alterarTema() {
  const dadosLocalStorage = JSON.parse(localStorage.getItem('personalizacao-unicpages')) || {}

  state.tema = state.tema === 'claro' ? 'escuro' : 'claro'

  localStorage.setItem('personalizacao-unicpages', JSON.stringify({ ...dadosLocalStorage, tema: state.tema }))

  document.body.setAttribute('tema', state.tema)
}

function renderizarComponentes() {
  state.componentesDesktop = storeCriar.componentes.desktop
  state.componentesMobile = storeCriar.componentes.mobile
}

function abrirModalReplicar() {
  storeModal.abrirModal('replicarMobile', 'mobile', 'Replicar para Mobile')
}

function abrirSecoesDesktop() {
  storeModal.abrirModal('secoesDesktop', 'desktop', 'Seções Desktop')
}

function abrirSecoesMobile() {
  storeModal.abrirModal('secoesMobile', 'mobile', 'Seções Mobile')
}

const initialZoom = window.innerWidth < 768 ? 0.1 : 0.25
const zoomLevel = ref(initialZoom)

const zoomIn = () => {
  zoomLevel.value += 0.05
  zoomBotaoDisabled()
}

const zoomOut = () => {
  zoomLevel.value = Math.max(zoomLevel.value - 0.05, 0.05)
  zoomBotaoDisabled()
}

const zoomInScroll = () => {
  zoomLevel.value += 0.01
  zoomBotaoDisabled()
}

const zoomOutScroll = () => {
  zoomLevel.value = Math.max(zoomLevel.value - 0.01, 0.05)
  zoomBotaoDisabled()
}

const zoomBotaoDisabled = () => {
  state.botaoZoomOut = zoomLevel.value <= 0.05
}

const adicionarClasseBloquear = () => {
  document.body.classList.add('bloquear')
}
const removerClasseBloquear = () => {
  document.body.classList.remove('bloquear')
}

const zoomComMouse = (event) => {
  if (event.metaKey || event.ctrlKey) {
    event.preventDefault()
    adicionarClasseBloquear()

    if (event.deltaY < 0) zoomInScroll()
    else if (event.deltaY > 0) zoomOutScroll()
  }
}

const lidarComTeclaPressionada = (event) => {
  if (event.metaKey || event.ctrlKey) adicionarClasseBloquear()
}

const lidarComTeclaLiberada = () => {
  removerClasseBloquear()
}

let distanciaInicial = null

const calcularDistancia = (toque1, toque2) => {
  const dx = toque2.clientX - toque1.clientX
  const dy = toque2.clientY - toque1.clientY
  return Math.sqrt(dx * dx + dy * dy)
}

const lidarComToqueInicio = (evento) => {
  if (evento.touches.length === 2) {
    distanciaInicial = calcularDistancia(evento.touches[0], evento.touches[1])
    evento.preventDefault()
  }
}

const lidarComToqueMovimento = (evento) => {
  if (evento.touches.length === 2 && distanciaInicial !== null) {
    const distanciaAtual = calcularDistancia(evento.touches[0], evento.touches[1])
    if (distanciaAtual > distanciaInicial) {
      zoomInScroll()
    } else {
      zoomOutScroll()
    }
    distanciaInicial = distanciaAtual
    evento.preventDefault()
  }
}

const lidarComToqueFim = (evento) => {
  if (evento.touches.length < 2) {
    distanciaInicial = null
  }
}

function centralizarTela() {
  const alturaDiv = 50000
  const larguraDiv = 50000

  const moveY = alturaDiv / 2 - window.innerHeight / 2
  const moveX = larguraDiv / 2 - window.innerWidth / 2

  window.scrollTo({ top: moveY, left: moveX, behavior: 'smooth' })
}

const removeClass = () => {
  if (tela.value && document.body.classList.contains('bloqueado')) {
    document.body.classList.remove('bloqueado')
  }
}

function verificarTela() {
  acessoDesktop.value = window.innerWidth >= 1024
}

onMounted(() => {
  const corSalva = localStorage.getItem('corFundoUnicPages')
  if (corSalva) {
    state.corFundo = corSalva
  }
  window.addEventListener('resize', verificarTela)
  renderizarComponentes()
  setTimeout(() => {
    centralizarTela()
  }, 500)
  emitter.on('renderizarComponentesTela', renderizarComponentes)
  window.addEventListener('wheel', zoomComMouse, { passive: false })
  window.addEventListener('keydown', lidarComTeclaPressionada)
  window.addEventListener('keyup', lidarComTeclaLiberada)
  if (tela.value) {
    tela.value.addEventListener('mouseover', removeClass)
  }
  if (!verificarTela()) {
    const elemento = tela.value
    elemento.addEventListener('touchstart', lidarComToqueInicio, { passive: false })
    elemento.addEventListener('touchmove', lidarComToqueMovimento, { passive: false })
    elemento.addEventListener('touchend', lidarComToqueFim, { passive: false })
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', verificarTela)
  window.removeEventListener('wheel', zoomComMouse, { passive: false })
  window.removeEventListener('keydown', lidarComTeclaPressionada)
  window.removeEventListener('keyup', lidarComTeclaLiberada)
  if (!verificarTela()) {
    const elemento = tela.value
    elemento.removeEventListener('touchstart', lidarComToqueInicio)
    elemento.removeEventListener('touchmove', lidarComToqueMovimento)
    elemento.removeEventListener('touchend', lidarComToqueFim)
  }
})
</script>

<style scoped>
section.tela {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100dvw;
  height: 100dvh;
  position: relative;
  scrollbar-width: none;
}

.avisoMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-1);
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 0 150px 0;
}

.avisoMobile .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--cor-cinza-3);
  margin: 0 0 30px 0;
}

.avisoMobile .icone svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
}

.avisoMobile p {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  width: 100%;
  max-width: 250px;
  line-height: 1.5;
  text-align: center;
}

.componente {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.botoes {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 250px;
  width: calc(100% - 500px);
  background-color: var(--cor-cinza-3);
  height: 50px;
  z-index: 9;
}

.botoes .botao {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0 10px;
  white-space: nowrap;
  transition: all 0.3s;
}

.botoes .botao.cor {
  position: relative;
  width: auto;
}

.botoes .botao.cor:hover {
  opacity: 1;
}

.botoes .botao.cor p {
  margin: 0 0 0 35px;
}

.botoes .botao p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.botoes .botao:disabled {
  pointer-events: none;
}

.botoes .botao:disabled p {
  color: var(--cor-cinza-5);
}

.botoes .botao:disabled svg {
  fill: var(--cor-cinza-5);
}

.botoes .botao:hover {
  opacity: 0.7;
}

.botoes .botao:active {
  opacity: 0.7;
}

.botoes .botao svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
  margin: 0 8px 0 0;
  transition: all 0.3s;
}

.cor {
  position: absolute;
  width: 100%;
  height: 100%;
}

.sites {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 50000px;
  min-height: 50000px;
  max-height: 50000px;
  width: 50000px;
  min-width: 50000px;
  max-width: 50000px;
  scrollbar-width: none;
}

.sites::-webkit-scrollbar {
  display: none;
}

.sites .conteudo {
  display: flex;
  align-items: flex-start;
  transition: transform 0.3s;
}

.site {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.site.desktop {
  max-width: 1500px;
  min-width: 1500px;
  margin: 0 1200px 0 0;
}

.site.mobile {
  max-width: 414px;
  min-width: 414px;
}

.nova {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1500px;
  height: 200px;
  background-color: var(--cor-azul);
  border-radius: 30px;
  padding: 0 100px;
  margin: 50px 0 0 0;
  transition: all 0.3s;
}

.nova.dois {
  background-color: var(--cor-roxo);
}

.site.mobile .nova {
  width: 414px;
  height: 150px;
  padding: 0 40px;
}

.site.mobile .nova svg {
  width: 35px;
  min-width: 35px;
  max-height: 35px;
  margin: 0 20px 0 0;
}

.site.mobile .nova p {
  font-size: var(--f4);
}

.nova.dois:hover {
  background-color: var(--cor-roxo-escuro);
}

.nova:hover {
  background-color: var(--cor-azul-escuro);
}

.nova svg {
  width: 60px;
  min-width: 60px;
  max-height: 60px;
  margin: 0 30px 0 0;
  fill: var(--cor-branco-fixo);
}

.nova p {
  font-family: var(--bold);
  font-size: var(--f6);
  color: var(--cor-branco-fixo);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .botoes {
    justify-content: flex-start;
    top: 60px;
    left: 0;
    width: 100%;
    height: 40px;
    padding: 0 0 0 20px;
    overflow-y: scroll;
  }
  .botoes .botao {
    padding: 0 20px 0 0;
  }
}
</style>
