<template>
  <section class="quadro" :class="{ ocultarElemento: state.store?.fundo?.hidden }">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarMobile />
    <ElementoSecao class="conteudo" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <ElementoDivSemFundo class="itens" :store="state.store" :elemento="prepararEvento('fundoItensUm')" @secaoSemFundo="secaoSemFundo">
        <ElementoQuadro v-for="item in itemsUm" :key="item" class="item" :store="state.store" :elemento="prepararEvento(`fundoUmQuadro${item}`)" @quadro="quadro"></ElementoQuadro>
      </ElementoDivSemFundo>

      <ElementoDivSemFundo class="itens" :store="state.store" :elemento="prepararEvento('fundoItensDois')" @secaoSemFundo="secaoSemFundo">
        <ElementoQuadro v-for="item in itemsDois" :key="item" class="item" :store="state.store" :elemento="prepararEvento(`fundoDoisQuadro${item}`)" @quadro="quadro"></ElementoQuadro>
      </ElementoDivSemFundo>

      <ElementoDivisor :store="state.store" :elemento="prepararEvento('divisor')" @divisor="divisor" class="divisor" />
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarMobile from '@components/criar/partials/EditarMobile.vue'

import ElementoDivisor from '@components/criar/elementos/divisor.vue'
import ElementoQuadro from '@components/criar/elementos/quadro.vue'
import ElementoSecao from '@components/criar/elementos/secao.vue'
import ElementoDivSemFundo from '@components/criar/elementos/divSemFundo.vue'
import ElementoAvisoFixed from '@components/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const itemsUm = ['Um', 'Dois', 'Tres', 'Quatro']
const itemsDois = ['Um', 'Dois', 'Tres', 'Quatro']

const state = reactive({
  indiceSecao: null,
  store: {}
})

function divisor(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesDivisor', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function secaoSemFundo(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecaoSemFundo', { evento })
}

function quadro(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesQuadro', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'mobile'
  }
  return evento
}

function storeDaSecao() {
  const storeMobile = storeAjustes.secoes.mobile
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeMobile.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
.itens {
  display: flex;
  position: relative;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  z-index: 2;
}
</style>
