<template>
  <div class="button">
    <div class="marcar" :class="{ marcado: storeCriar.elementoMarcado === idUnico }"></div>
    <button :id="elementoDinamico.id" @click="emitirEvento(props.elemento)" :class="{ ocultarElemento: elementoDinamico.hidden }" :style="estiloDinamico">
      <h5 v-html="helperTexto.converterTextoEmFormatoHtml(elementoDinamico.text, elementoDinamico.spanColor, elementoDinamico.spanBackground)"></h5>
      <div class="background" :style="estiloDinamicoBg"></div>
    </button>
  </div>
</template>

<script setup>
import { computed, watchEffect } from 'vue'
import { helperTexto } from '@helpers'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()

const props = defineProps({
  elemento: Object,
  store: Object
})

const emit = defineEmits(['systeme'])

const elementoDinamico = computed(() => props.store?.[props.elemento?.elementoSelecionado] ?? {})
const idUnico = computed(() => props.elemento?.elementoSelecionado + props.elemento?.indiceSecao + props.elemento?.dispositivo ?? {})

const estiloDinamicoBg = computed(() => ({
  background: `linear-gradient(${elementoDinamico.value.gradientDeg}deg, #${elementoDinamico.value.gradientPrimary}, #${elementoDinamico.value.gradientSecondary})`,
  borderRadius: `${elementoDinamico.value.borderRadius?.top}px ${elementoDinamico.value.borderRadius?.right}px ${elementoDinamico.value.borderRadius?.bottom}px ${elementoDinamico.value.borderRadius?.left}px`
}))

const estiloDinamico = computed(() => {
  const fontFamily = elementoDinamico.value.fontFamily || ''
  const [nomeFonte, estiloFonte] = fontFamily.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte,
    fontWeight: estiloFonte,
    fontSize: `${elementoDinamico.value.fontSize}px`,
    color: `#${elementoDinamico.value.color}`,
    backgroundColor: `#${elementoDinamico.value.backgroundColor}`,
    textAlign: elementoDinamico.value.textAlign,
    letterSpacing: `${elementoDinamico.value.letterSpacing}px`,
    textDecoration: elementoDinamico.value.textDecoration,
    lineHeight: `${elementoDinamico.value.lineHeight}%`,
    maxWidth: `${elementoDinamico.value.maxWidth}px`,
    margin: `${elementoDinamico.value.margin?.top}px ${elementoDinamico.value.margin?.right}px ${elementoDinamico.value.margin?.bottom}px ${elementoDinamico.value.margin?.left}px`,
    padding: `${elementoDinamico.value.padding?.top}px ${elementoDinamico.value.padding?.right}px ${elementoDinamico.value.padding?.bottom}px ${elementoDinamico.value.padding?.left}px`,
    borderRadius: `${elementoDinamico.value.borderRadius?.top}px ${elementoDinamico.value.borderRadius?.right}px ${elementoDinamico.value.borderRadius?.bottom}px ${elementoDinamico.value.borderRadius?.left}px`
  }
})

function emitirEvento(elemento) {
  const idUnico = elemento.elementoSelecionado + elemento.indiceSecao + elemento.dispositivo
  if (elemento) {
    storeCriar.elementoMarcado = storeCriar.elementoMarcado === idUnico ? '' : idUnico
    emit('systeme', elemento.elementoSelecionado)
  }
}

function carregarFonte(fonte) {
  if (!fonte) return

  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

watchEffect(() => {
  carregarFonte(elementoDinamico.value.fontFamily)
})
</script>

<style scoped>
button {
  position: relative;
}

button h5 {
  z-index: 2;
  position: relative;
}
</style>
