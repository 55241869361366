<template>
  <section
    class="footer"
    :class="{ ocultarElemento: state.store?.fundo?.hidden }"
    :style="`
    flex-direction: ${state.store?.fundo?.direction};
  `">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarMobile />
    <ElementoSecao class="conteudo" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <ElementoDivSemFundo class="opcoes" :store="state.store" :elemento="prepararEvento('fundoTextoUm')" @secaoSemFundo="secaoSemFundo">
        <ElementoImg :store="state.store" :elemento="prepararEvento('logo')" @imagem="imagem" />
        <ElementoH2 :store="state.store" :elemento="prepararEvento('titulo')" @texto="texto" />
        <ElementoP :store="state.store" :elemento="prepararEvento('subtitulo')" @texto="texto" />
      </ElementoDivSemFundo>
      <ElementoDivSemFundo class="opcoes" :store="state.store" :elemento="prepararEvento('fundoTextoDois')" @secaoSemFundo="secaoSemFundo">
        <ElementoH2 :store="state.store" :elemento="prepararEvento('tituloDois')" @texto="texto" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao1')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao2')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao3')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao4')" @botao="botao" />
      </ElementoDivSemFundo>
      <ElementoDivSemFundo class="opcoes" :store="state.store" :elemento="prepararEvento('fundoTextoTres')" @secaoSemFundo="secaoSemFundo">
        <ElementoH2 :store="state.store" :elemento="prepararEvento('tituloTres')" @texto="texto" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao5')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao6')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao7')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao8')" @botao="botao" />
      </ElementoDivSemFundo>
      <ElementoDivSemFundo class="opcoes" :store="state.store" :elemento="prepararEvento('fundoTextoQuatro')" @secaoSemFundo="secaoSemFundo">
        <ElementoH2 :store="state.store" :elemento="prepararEvento('tituloQuatro')" @texto="texto" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao9')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao10')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao11')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao12')" @botao="botao" />
      </ElementoDivSemFundo>
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarMobile from '@components/criar/partials/EditarMobile.vue'

import ElementoButton from '@components/criar/elementos/button.vue'
import ElementoImg from '@components/criar/elementos/img.vue'
import ElementoH2 from '@components/criar/elementos/h2.vue'
import ElementoP from '@components/criar/elementos/p.vue'
import ElementoSecao from '@components/criar/elementos/secao.vue'
import ElementoDivSemFundo from '@components/criar/elementos/divSemFundo.vue'
import ElementoAvisoFixed from '@components/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const state = reactive({
  indiceSecao: null,
  store: {}
})

function imagem(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagem', { evento })
}

function botao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesBotao', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function texto(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesTexto', { evento })
}

function secaoSemFundo(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecaoSemFundo', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'mobile'
  }
  return evento
}

function storeDaSecao() {
  const storeMobile = storeAjustes.secoes.mobile
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeMobile.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
.opcoes {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}
</style>
