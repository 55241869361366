<template>
  <div class="text">
    <h3>Texto</h3>
    <textarea rows="3" spellCheck="false" placeholder="Digite aqui" @input="editarText()" v-model="state.text" v-redimensionar-textarea></textarea>

    <!-- IA Section -->
    <div class="unicai">
      <button class="abrir" @click="abrirUnika()" :class="{ ativo: state.unicAi }">
        <Svgs nome="ia" />
        <p>Poder da I.A</p>
      </button>

      <!-- Chat Unika -->
      <div class="chat" :class="{ mostrar: state.unicAi }">
        <div class="titulo">
          <p>Unika, I.A da UnicPages</p>
          <button class="fechar" @click="fecharChat()">
            <Svgs nome="x" />
          </button>
        </div>

        <div class="conteudo">
          <!-- Opções -->
          <div class="opcoes">
            <button @click="resumirTexto()">
              <Svgs nome="cortar" />
              <p>Resumir</p>
              <p>Texto</p>
            </button>
            <button @click="melhorarTexto()">
              <Svgs nome="foguete" />
              <p>Melhorar</p>
              <p>Texto</p>
            </button>
            <button :class="{ ativo: state.criar }" @click="state.criar = true">
              <Svgs nome="ideia" />
              <p>Criar</p>
              <p>Texto</p>
            </button>
          </div>

          <!-- Criar Texto -->
          <div class="criar" v-if="state.criar">
            <h3>Descreva como deseja o texto</h3>
            <p class="bom">
              <Svgs nome="check" />
              Exemplo Bom: Crie um título sobre UnicPages, um criador de sites brasileiro, seja direto e apelativo
            </p>
            <p class="ruim">
              <Svgs nome="x" />
              Exemplo Ruim: Crie um título para mim
            </p>
            <textarea placeholder="Digite aqui..." rows="2" v-model="state.comandoCriacao" v-redimensionar-textarea></textarea>
            <button @click="criarTexto()">Gerar texto</button>
          </div>

          <!-- Resultado -->
          <div class="resultado" v-if="state.resultado">
            <span v-if="!state.resultadoGerado"></span>
            <h3 v-if="state.resultadoGerado">{{ state.resultadoGerado }}</h3>
            <button v-if="state.resultadoGerado" @click="usarTextoGerado()">Usar esse texto</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect, inject } from 'vue'
import { useStoreAjustes, useStorePerfil, useStoreGpt } from '@stores'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storeAjustes = useStoreAjustes()
const storeGpt = useStoreGpt()
const emitter = inject('emitter')

const props = defineProps(['selecao'])
const state = reactive({
  text: '',
  unicAi: false,
  criar: false,
  resultado: false,
  resultadoGerado: '',
  comandoCriacao: ''
})

function carregarText() {
  state.text = elementoSelecionado().text
}

function editarText() {
  elementoSelecionado().text = state.text
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

function abrirUnika() {
  if (storePerfil.usuario?.assinatura?.tipo === 'free') {
    emitter.emit('mostrarAlerta', { tipo: 'upgrade', mensagem: 'Faça upgrade para ter acesso a Unika I.A' })
  } else {
    state.unicAi = !state.unicAi
  }
}

async function chamarGpt(prompt) {
  state.resultado = true
  state.resultadoGerado = ''
  try {
    const resposta = await storeGpt.chat({ mensagem: prompt })
    state.resultadoGerado = resposta
  } catch (error) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao gerar o texto, tente novamente.' })
  }
}

async function resumirTexto() {
  state.criar = false
  let textoAtual = state.resultadoGerado || elementoSelecionado().text
  const prompt = `O texto é: ${textoAtual}. OBS: Diminua o texto, sem perder contexto (porém o texto deve ficar menor do que é agora). Se necessário retire algumas partes para ficar ainda menor. Analise muito bem o texto para melhorar como um copywriter profissional. Envie somente ele sem aspas ou explicações.`
  await chamarGpt(prompt)
}

async function melhorarTexto() {
  state.criar = false
  let textoAtual = state.resultadoGerado || elementoSelecionado().text
  const prompt = `O texto é: ${textoAtual}. OBS: Melhore o texto com habilidades de um copywriter profissional que estudou as melhores técnicas de copy do mundo. Mantenha o contexto, mas deixe o texto muito melhor porém sem deixar ele gigantesco, melhore mas mantendo o mais próximo do tamanho atual. Envie somente ele sem aspas ou explicações. `
  await chamarGpt(prompt)
}

async function criarTexto() {
  if (!state.comandoCriacao.trim()) return
  const prompt = `O propmt para gerar ele é: ${state.comandoCriacao}. OBS: Gere um texto com habilidades de um copywriter profissional que estudou as melhores técnicas de copy do mundo, envie sem aspas em volta do texto ou descrição, envie somente o texto diretamente`
  state.criar = false
  await chamarGpt(prompt)
}

function usarTextoGerado() {
  elementoSelecionado().text = state.resultadoGerado
  state.text = state.resultadoGerado
  state.resultadoGerado = ''
  state.resultado = false
  state.criar = false
  state.unicAi = false
}

function fecharChat() {
  state.resultado = false
  state.criar = false
  state.unicAi = false
}

watchEffect(() => {
  carregarText()
})
</script>

<style scoped>
.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
}

h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

textarea {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  padding: 15px;
  border-radius: 10px;
  resize: none;
  line-height: 1.5;
  width: 100%;
  transition: all 0.3s;
}

textarea:focus {
  background-color: var(--cor-cinza-4);
}

textarea::placeholder {
  color: var(--cor-cinza-5);
}

.unicai {
  width: 100%;
}

button.abrir {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  transition: all 0.3s;
  position: absolute;
  top: 20px;
  right: 20px;
}

button.abrir.ativo p {
  color: var(--cor-branco);
}

button.abrir:hover p {
  color: var(--cor-branco);
}

button.abrir svg {
  width: 13px;
  max-width: 13px;
  max-height: 13px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

button.abrir p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}

.chat.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 100px;
  right: 270px;
  width: 100%;
  max-width: 250px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px 20px 0 20px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 11;
  transition: all 0.5s;
}

.chat::-webkit-scrollbar-thumb {
  background-color: var(--cor-cinza-3);
  border-radius: 0 10px 10px 0;
}

.chat::-webkit-scrollbar-track {
  background-color: transparent;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0 20px 0;
}

.chat .titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--cor-cinza-2);
  border-radius: 10px 10px 0 0;
  z-index: 2;
}

.chat .titulo p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.chat button.fechar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: transparent;
}

.chat button.fechar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  position: inherit;
  margin: 0;
  transition: all 0.3s;
}

.chat button.fechar:hover svg {
  fill: var(--cor-vermelho);
}

.chat .opcoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chat .opcoes button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: calc(33.33% - 5px);
  padding: 15px 0;
  transition: all 0.3s;
}

.chat .opcoes button:hover {
  background-color: var(--cor-cinza-4);
}

.chat .opcoes button.ativo {
  background-color: var(--cor-azul);
}

.chat .opcoes button.ativo svg {
  fill: var(--cor-branco-fixo);
}

.chat .opcoes button.ativo p {
  color: var(--cor-branco-fixo);
}

.chat .opcoes button svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
  margin: 0 0 5px 0;
  transition: all 0.3s;
}

.chat .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
  transition: all 0.3s;
}

.chat .criar {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0 0 0;
}

.chat .criar p {
  display: flex;
  align-items: flex-start;
  font-family: var(--regular);
  font-size: var(--f0);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 0 0 10px 0;
  width: 100%;
}

.chat .criar p svg {
  width: 10px;
  min-width: 10px;
  margin: 3px 10px 0 0;
}

.chat .criar p.ruim svg {
  fill: var(--cor-vermelho);
}

.chat .criar p.bom svg {
  fill: var(--cor-verde);
}

.chat .criar button {
  padding: 15px;
  border-radius: 5px;
  background-color: var(--cor-azul);
  margin: 10px 0 0 0;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  width: 100%;
  transition: all 0.3s;
}

.chat .criar button:hover {
  background-color: var(--cor-azul-escuro);
}

.chat .resultado {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0 0 0;
}

.chat .resultado span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid var(--cor-azul);
  border-top: 3px solid transparent;
  animation: girando 0.6s linear infinite;
  margin: 5px 0 15px 0;
}

.chat .resultado h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  line-height: 1.5;
  text-align: left;
  width: 100%;
  margin: 0 0 5px 0;
  word-break: break-all;
}

.chat .resultado button {
  padding: 15px;
  border-radius: 5px;
  background-color: var(--cor-azul);
  margin: 10px 0 0 0;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  width: 100%;
  transition: all 0.3s;
}

.chat .resultado button:hover {
  background-color: var(--cor-azul-escuro);
}

@media screen and (max-width: 1024px) {
  .chat {
    right: 20px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    height: 55dvh;
    max-height: 55dvh;
    min-height: 55dvh;
  }

  .conteudo {
    height: 55dvh;
    max-height: 55dvh;
  }
}
</style>
