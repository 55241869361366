<template>
  <div class="p">
    <div class="marcar" :class="{ marcado: storeCriar.elementoMarcado === idUnico }"></div>
    <p :id="elementoDinamico.id" @click="emitirEvento(props.elemento)" :class="{ ocultarElemento: elementoDinamico.hidden }" :style="estiloDinamico" v-html="helperTexto.converterTextoEmFormatoHtml(elementoDinamico.text, elementoDinamico.spanColor, elementoDinamico.spanBackground)"></p>
  </div>
</template>

<script setup>
import { computed, watchEffect } from 'vue'
import { helperTexto } from '@helpers'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()

const props = defineProps({
  elemento: Object,
  store: Object
})

const emit = defineEmits(['texto'])
const elementoDinamico = computed(() => props.store?.[props.elemento?.elementoSelecionado] ?? {})
const idUnico = computed(() => props.elemento?.elementoSelecionado + props.elemento?.indiceSecao + props.elemento?.dispositivo ?? {})

const estiloDinamico = computed(() => {
  const fontFamily = elementoDinamico.value.fontFamily || ''
  const [nomeFonte, estiloFonte] = fontFamily.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte,
    fontWeight: estiloFonte,
    fontSize: `${elementoDinamico.value.fontSize}px`,
    color: `#${elementoDinamico.value.color}`,
    textAlign: elementoDinamico.value.textAlign,
    letterSpacing: `${elementoDinamico.value.letterSpacing}px`,
    textDecoration: elementoDinamico.value.textDecoration,
    lineHeight: `${elementoDinamico.value.lineHeight}%`,
    maxWidth: `${elementoDinamico.value.maxWidth}px`,
    margin: `${elementoDinamico.value.margin?.top}px ${elementoDinamico.value.margin?.right}px ${elementoDinamico.value.margin?.bottom}px ${elementoDinamico.value.margin?.left}px`
  }
})

function emitirEvento(elemento) {
  const idUnico = elemento.elementoSelecionado + elemento.indiceSecao + elemento.dispositivo
  if (elemento) {
    storeCriar.elementoMarcado = storeCriar.elementoMarcado === idUnico ? '' : idUnico
    emit('texto', elemento.elementoSelecionado)
  }
}

function carregarFonte(fonte) {
  if (!fonte) return

  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

watchEffect(() => {
  carregarFonte(elementoDinamico.value.fontFamily)
})
</script>
