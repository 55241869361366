<template>
  <section class="videos">
    <h2>Configurando sua plataforma</h2>
    <button class="seta voltar" @click="voltarSlide()">
      <Svgs nome="setinha" />
    </button>
    <button class="seta avancar" @click="avancarSlide()">
      <Svgs nome="setinha" />
    </button>
    <div class="slide" ref="slider">
      <div class="item" v-for="(video, index) in state.videos" :key="`video-${index}`" @click="storeModal.abrirModalVideo('verVideo', video.titulo, video.descricao, video.url)">
        <div class="capa" :style="`background-image: url('${video.capa}')`">
          <h4>{{ video.duracao }}</h4>
        </div>
        <div class="texto">
          <h3>{{ video.titulo }}</h3>
          <p>{{ video.descricao }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useStoreModal } from '@stores'
import Svgs from '@svgs'

const slider = ref(null)
const storeModal = useStoreModal()

function avancarSlide() {
  if (slider.value) {
    slider.value.scrollBy({
      left: 300,
      behavior: 'smooth'
    })
  }
}

function voltarSlide() {
  if (slider.value) {
    slider.value.scrollBy({
      left: -300,
      behavior: 'smooth'
    })
  }
}

const state = reactive({
  videos: [
    {
      capa: 'https://arquivos.unicpages.com/imagens/app/unicflix/configurar-dominio.png',
      duracao: '11:35',
      titulo: 'Configure seu domínio em segundos no seu site Unic',
      descricao: 'Veja como configurar seu domínio de forma prática e rápida.',
      url: 'https://iframe.mediadelivery.net/embed/342141/e892cbaa-77fa-4437-938b-9c5b9e44ee53'
    },
    {
      capa: 'https://arquivos.unicpages.com/imagens/app/unicflix/botao-scroll-para-secao.png',
      duracao: '11:50',
      titulo: 'Scroll na tela ao clicar em botão e dicas extras sobre links',
      descricao: 'Configure o scroll suave na sua página ao clicar em um botão.',
      url: 'https://iframe.mediadelivery.net/embed/342141/9aa6fbe0-efe0-4531-9159-2ea546358e12'
    },
    {
      capa: 'https://arquivos.unicpages.com/imagens/app/unicflix/compartilhar-site.png',
      duracao: '13:36',
      titulo: 'Libere acesso para outros editarem o seu site de forma ilimitada',
      descricao: 'Descubra como compartilhar seu site de forma colaborativa.',
      url: 'https://iframe.mediadelivery.net/embed/342141/e552b375-42d9-48f9-8f58-6c5a29481f92'
    },
    {
      capa: 'https://arquivos.unicpages.com/imagens/app/unicflix/link-bio-unika.png',
      duracao: '16:07',
      titulo: 'Criando o seu link de BIO com Unika, I.A da UnicPages',
      descricao: 'Descubra como criar links de BIO com inteligência artificial.',
      url: 'https://iframe.mediadelivery.net/embed/342141/8a2841e8-6715-4fbe-b815-42b5290731ce'
    }
  ]
})
</script>

<style scoped>
section.videos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #0c0c0e;
  position: relative;
}
h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco-fixo);
  padding: 0 0 0 80px;
}
button.seta {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  z-index: 3;
}
button.seta.avancar:hover svg {
  transform: rotate(-90deg) scale(1.5);
}
button.seta.voltar:hover svg {
  transform: rotate(90deg) scale(1.5);
}
button.seta svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}
button.seta.avancar {
  right: 0;
  bottom: 0;
  background: linear-gradient(-90deg, #0c0c0e 20%, #0c0c0e00);
  padding: 0 15px 50px 40px;
}
button.seta.avancar svg {
  transform: rotate(-90deg);
}
button.seta.voltar {
  left: 0;
  bottom: 0;
  background: linear-gradient(90deg, #0c0c0e 20%, #0c0c0e00);
  padding: 0 40px 50px 15px;
}
button.seta.voltar svg {
  transform: rotate(90deg);
}
.slide {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 60px 80px 80px;
  overflow-x: scroll;
  scrollbar-width: none;
}
.slide::-webkit-scrollbar {
  display: none;
}
.slide .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  min-width: 280px;
  margin: 0 20px 0 0;
  background-color: #141416;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.slide .item:hover {
  background-color: #1b1b1d;
}
.slide .item .capa {
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
  position: relative;
}
.slide .item .capa h4 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  background-color: #000000;
  padding: 2px 4px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 5px;
}
.slide .item .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.slide .item .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  line-height: 1.5;
}
.slide .item .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 0 0;
}
@media screen and (max-width: 1000px) {
  h2 {
    font-family: var(--bold);
    font-size: var(--f3);
    color: var(--cor-branco-fixo);
    padding: 0 0 0 20px;
  }
  button.seta {
    display: none;
  }
  .slide {
    padding: 20px 5px 80px 20px;
  }
  .slide .item {
    margin: 0 15px 0 0;
  }
}
</style>
