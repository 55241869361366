<template>
  <div id="secoes">
    <div class="nome um">
      <h2>Seções Prontas</h2>
      <p>Dezenas de seções já prontas com design criado</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'secao')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="botoes">
          <h3>{{ secao.nome }}</h3>
          <button class="um" @click="secao.acao">Adicionar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive } from 'vue'
import { useStoreCriar, useStoreAjustes, useStoreModal } from '@stores'
import { helperId } from '@helpers'

import { useStoreSecao1 } from '@components/criar/secoes/prontas/secao1.js'
import { useStoreSecao2 } from '@components/criar/secoes/prontas/secao2.js'
import { useStoreSecao3 } from '@components/criar/secoes/prontas/secao3.js'
import { useStoreSecao4 } from '@components/criar/secoes/prontas/secao4.js'
import { useStoreSecao5 } from '@components/criar/secoes/prontas/secao5.js'
import { useStoreSecao6 } from '@components/criar/secoes/prontas/secao6.js'
import { useStoreSecao7 } from '@components/criar/secoes/prontas/secao7.js'
import { useStoreSecao8 } from '@components/criar/secoes/prontas/secao8.js'
import { useStoreSecao9 } from '@components/criar/secoes/prontas/secao9.js'
import { useStoreSecao10 } from '@components/criar/secoes/prontas/secao10.js'
import { useStoreSecao11 } from '@components/criar/secoes/prontas/secao11.js'
import { useStoreSecao12 } from '@components/criar/secoes/prontas/secao12.js'
import { useStoreSecao13 } from '@components/criar/secoes/prontas/secao13.js'
import { useStoreSecao14 } from '@components/criar/secoes/prontas/secao14.js'
import { useStoreSecao15 } from '@components/criar/secoes/prontas/secao15.js'
import { useStoreSecao16 } from '@components/criar/secoes/prontas/secao16.js'
import { useStoreSecao17 } from '@components/criar/secoes/prontas/secao17.js'
import { useStoreSecao18 } from '@components/criar/secoes/prontas/secao18.js'
import { useStoreSecao19 } from '@components/criar/secoes/prontas/secao19.js'
import { useStoreSecao20 } from '@components/criar/secoes/prontas/secao20.js'
import { useStoreSecao21 } from '@components/criar/secoes/prontas/secao21.js'
import { useStoreSecao22 } from '@components/criar/secoes/prontas/secao22.js'
import { useStoreSecao23 } from '@components/criar/secoes/prontas/secao23.js'
import { useStoreSecao24 } from '@components/criar/secoes/prontas/secao24.js'
import { useStoreSecao25 } from '@components/criar/secoes/prontas/secao25.js'

const emitter = inject('emitter')
const storeCriar = useStoreCriar()
const storeAjustes = useStoreAjustes()
const storeModal = useStoreModal()

function adicionarSecao(secao, storeFunc) {
  const id = helperId.random(12)
  const store = sanitizarStore(storeFunc())

  storeCriar.adicionarSecao(secao, id, true)
  storeAjustes.adicionarSecao(secao, id, store, true)
  emitter.emit('renderizarComponentesTela')
  emitter.emit('atualizarCamadasSite')
  storeModal.fecharModal('secoesDesktop')
}

function sanitizarStore(store) {
  const clone = { ...store }

  for (const key in clone) {
    if (key.startsWith('_')) delete clone[key]
    if (key.startsWith('$')) delete clone[key]
  }

  return JSON.parse(JSON.stringify(clone))
}

const state = reactive({
  secoes: [
    {
      nome: '25ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-28.png',
      acao: () => adicionarSecao('Footer1Desktop', useStoreSecao25),
      categoria: 'secao'
    },
    {
      nome: '24ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-27.png',
      acao: () => adicionarSecao('Quadro1Desktop', useStoreSecao24),
      categoria: 'secao'
    },
    {
      nome: '23ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-26.png',
      acao: () => adicionarSecao('Duvida1Desktop', useStoreSecao23),
      categoria: 'secao'
    },
    {
      nome: '22ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-25.png',
      acao: () => adicionarSecao('Plano3Desktop', useStoreSecao22),
      categoria: 'secao'
    },
    {
      nome: '21ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-24.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao21),
      categoria: 'secao'
    },
    {
      nome: '20ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-23.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao20),
      categoria: 'secao'
    },
    {
      nome: '19ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-21.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao19),
      categoria: 'secao'
    },
    {
      nome: '18ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-20.png',
      acao: () => adicionarSecao('Footer1Desktop', useStoreSecao18),
      categoria: 'secao'
    },
    {
      nome: '17ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-19.png',
      acao: () => adicionarSecao('Quadro1Desktop', useStoreSecao17),
      categoria: 'secao'
    },
    {
      nome: '16ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-18.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao16),
      categoria: 'secao'
    },
    {
      nome: '15ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-15.png',
      acao: () => adicionarSecao('Plano3Desktop', useStoreSecao15),
      categoria: 'secao'
    },
    {
      nome: '14ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-14.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao14),
      categoria: 'secao'
    },
    {
      nome: '13ª | Formulário com Discord',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-13.png',
      acao: () => adicionarSecao('Formulario1Desktop', useStoreSecao13),
      categoria: 'secao'
    },
    {
      nome: '12ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-12.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao12),
      categoria: 'secao'
    },
    {
      nome: '11ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-11.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao11),
      categoria: 'secao'
    },
    {
      nome: '10ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-10.png',
      acao: () => adicionarSecao('Topo1Desktop', useStoreSecao10),
      categoria: 'secao'
    },
    {
      nome: '9ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-9.png',
      acao: () => adicionarSecao('Quadro1Desktop', useStoreSecao9),
      categoria: 'secao'
    },
    {
      nome: '8ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-8.png',
      acao: () => adicionarSecao('Topo5Desktop', useStoreSecao8),
      categoria: 'secao'
    },
    {
      nome: '7ª | Formulário com Discord',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-7.png',
      acao: () => adicionarSecao('Formulario1Desktop', useStoreSecao7),
      categoria: 'secao'
    },
    {
      nome: '6ª | Formulário do Discord',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-6.png',
      acao: () => adicionarSecao('Formulario1Desktop', useStoreSecao6),
      categoria: 'secao'
    },
    {
      nome: '5ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-5.png',
      acao: () => adicionarSecao('Topo1Desktop', useStoreSecao5),
      categoria: 'secao'
    },
    {
      nome: '4ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-4.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao4),
      categoria: 'secao'
    },
    {
      nome: '3ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-3.png',
      acao: () => adicionarSecao('Topo1Desktop', useStoreSecao3),
      categoria: 'secao'
    },
    {
      nome: '2ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-2.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao2),
      categoria: 'secao'
    },
    {
      nome: '1ª',
      capaUrl: 'https://arquivos.unicpages.com/imagens/app/secoes/prontas/layout-1.png',
      acao: () => adicionarSecao('Quadro2Desktop', useStoreSecao1),
      categoria: 'secao'
    }
  ]
})
</script>

<style scoped>
#secoes {
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: fadeIn 0.3s linear;
}
.secoes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.nome {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0 20px 0;
  padding: 30px 0 0 0;
  border-top: 2px solid var(--cor-cinza-3);
}

.nome.um {
  padding: 0;
  margin: 0 0 20px 0;
  border-top: none;
}

.nome h2 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 10px 0 0;
}

.nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.secao {
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 10px);
  margin: 0 10px 20px 0;
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--cor-cinza-3);
}

.secao .capa {
  width: 100%;
}

.secao .capa .tamanho {
  position: relative;
  padding-bottom: 56%;
  width: 100%;
  height: 100%;
}

.secao .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-preto);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
}

.secao .botoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-3);
  padding: 10px 10px 10px 20px;
  border-radius: 0 0 10px 10px;
}

.secao .botoes button {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  border-radius: 10px;
  padding: 10px 13px;
  transition: all 0.3s;
}

.secao.breve {
  pointer-events: none;
}

.secao .botoes button:hover {
  background-color: var(--cor-azul-escuro);
}

.secao .botoes h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .secao {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 20px 0;
    position: relative;
    border-radius: 10px;
  }

  .nome {
    flex-direction: column;
    align-items: flex-start;
  }

  .nome h2 {
    margin: 0 0 5px 0;
  }
}
</style>
