<template>
  <Modal nome="subirSite">
    <div class="sucesso" :class="{ mostrar: state.confirmado }">
      <div class="icone">
        <svg viewBox="0 0 486 605" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M377.226 19.6255H16.874V366.615C16.874 410.877 26.0161 449.215 44.3236 481.628C62.6078 514.04 88.7247 539.204 122.674 557.118C156.601 575.032 196.653 584.001 242.784 584.001C288.915 584.001 329.879 575.032 364.086 557.118C398.293 539.204 424.784 514.04 443.629 481.628C462.451 449.215 471.874 410.877 471.874 366.615V19.6255H377.273H377.226ZM432.359 282.365H213.511V64.887H432.359V282.365Z" stroke="#1ead57" stroke-width="20" class="animate-path" />
        </svg>
      </div>
      <h3>Seu site está no ar!</h3>
      <p>Todas as atualizações foram atualizadas</p>
      <div class="links">
        <a :href="`https://unic.page/${page?.hotlink}`" target="_blank">
          <Svgs nome="link" />
          <h4>{{ `unic.page/${page?.hotlink}` }}</h4>
        </a>
        <a :href="`https://${page.dominioPersonalizado}${page.dominioRota ? '/' + page.dominioRota : ''}`" target="_blank" v-if="page?.dominioPersonalizado">
          <Svgs nome="link" />
          <h4>
            {{ `${page.dominioPersonalizado}${page.dominioRota ? '/' + page.dominioRota : ''}` }}
          </h4>
        </a>
      </div>
    </div>

    <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
      <span>
        <Svgs nome="check" />
      </span>
      <p>Sim, desejo subir para o ar as alterações</p>
    </div>

    <div class="botao">
      <BotaoSalvar nome="Subir para o ar" @click="publicarSite" :disabled="state.confirmando" />
    </div>
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useStorePages } from '@stores'
import Svgs from '@svgs'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Modal from '@components/global/modal/Modal.vue'

const emitter = inject('emitter')
const storePages = useStorePages()
const { page } = storeToRefs(storePages)

const state = reactive({
  confirmado: false,
  confirmar: false,
  confirmando: false
})

async function publicarSite() {
  if (state.confirmar) {
    state.confirmando = true

    const idPage = storePages.page._id

    try {
      await storePages.publicar(idPage)
      state.confirmado = true
    } catch (error) {
      console.error('Erro ao publicar:', error)
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao publicar o site' })
    } finally {
      state.confirmando = false
    }
  } else {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Marque a pergunta para subir' })
  }
}

function limparCampos() {
  state.confirmado = false
  state.confirmar = false
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.sucesso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: var(--cor-cinza-1);
  border-radius: 10px;
  padding: 0 30px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  transition: all 0.5s;
}

.sucesso.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.sucesso .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.sucesso .icone svg {
  width: 40px;
}

.animate-path {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: draw 4s linear infinite;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 2000;
    fill: #1ead5720;
  }
  50% {
    stroke-dashoffset: 0;
    fill: #1ead5750;
  }
  100% {
    stroke-dashoffset: 2000;
    fill: #1ead5720;
  }
}

.sucesso h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 20px 0 10px 0;
}

.sucesso p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  max-width: 350px;
}

.sucesso .links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0 0 0;
}

.sucesso .links a:nth-child(2) {
  margin: 0 0 0 15px;
}

.sucesso .links a {
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: all 0.3s;
}

.sucesso .links a:hover {
  opacity: 0.7;
}

.sucesso .links a h4 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-azul);
}

.sucesso .links a svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-azul);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.botao {
  width: 100%;
}
</style>
