<template>
  <section class="topo">
    <div class="texto">
      <Svgs nome="logo-flat" class="logo" />
      <h2>Tour pela Plataforma</h2>
      <p>Veja todo potencial que a tecnologia Unic vai colocar em suas mãos</p>
      <button @click="storeModal.abrirModalVideo('verVideo', 'Tour completo na Plataforma', 'Veja todo potencial que a tecnologia Unic vai colocar em suas mãos', 'https://iframe.mediadelivery.net/embed/342141/955bef23-57ef-4b4e-aadf-27bfdf25cce7')">
        <Svgs nome="play" />
        Assistir Tour
      </button>
    </div>
    <div class="fundo"></div>
    <video autoplay muted loop playsinline class="video-bg">
      <source src="https://arquivos.unicpages.com/imagens/app/unicflix/fundo-unicflix.mp4" type="video/mp4" />
      Seu navegador não suporta vídeos HTML5.
    </video>
  </section>
</template>

<script setup>
import Svgs from '@svgs'
import { useStoreModal } from '@stores'

const storeModal = useStoreModal()
</script>

<style scoped>
section.topo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #0b0c0e;
  overflow: hidden;
}
.fundo {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #0b0c0e, #0b0c0e00);
  z-index: 1;
}
video {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
}
.texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 150px 80px;
  width: 100%;
  z-index: 4;
}
.texto svg.logo {
  width: 200px;
  min-width: 200px;
}
.texto h2 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco-fixo);
  margin: 20px 0 0 0;
}
.texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  opacity: 0.8;
  margin: 10px 0 15px 0;
  width: 100%;
  max-width: 500px;
  line-height: 1.5;
}
.texto button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  padding: 15px 25px;
  border-radius: 10px;
  transition: all 0.3s;
}
.texto button:hover {
  background-color: var(--cor-azul-escuro);
}
.texto button.upgrade {
  background-color: var(--cor-laranja);
}
.texto button.upgrade:hover {
  background-color: var(--cor-laranja-escuro);
}
.texto button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}
@media screen and (max-width: 1000px) {
  video {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    width: auto;
    height: 100%;
  }
  .texto {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 130px 20px 100px 20px;
    width: 100%;
    z-index: 4;
  }
  .texto svg.logo {
    width: 140px;
    min-width: 140px;
  }
  .texto h2 {
    font-size: var(--f4);
    width: 100%;
    max-width: 260px;
    line-height: 1.4;
  }
  .texto p {
    font-size: var(--f2);
    width: 100%;
    max-width: 320px;
    line-height: 1.5;
  }
  .texto button {
    padding: 13px 20px;
  }
  .texto button svg {
    width: 13px;
    min-width: 13px;
  }
}
</style>
