<template>
  <div class="site">
    <AutoLoad />
    <AutoSave />
    <div class="componente" v-for="item in state.componentesMobile" :key="item.id">
      <component :is="componentMapMobile[item.nome]" :id="item.id" />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, reactive, onMounted, shallowRef, inject } from 'vue'
import { useStoreCriar, useStorePages } from '@stores'
import { useRouter } from 'vue-router'

import AutoSave from '@/components/criar/partials/AutoSave.vue'
import AutoLoad from '@/components/criar/partials/AutoLoad.vue'

const emitter = inject('emitter')
const router = useRouter()
const storeCriar = useStoreCriar()
const storePages = useStorePages()

const state = reactive({
  componentesMobile: []
})

async function carregarPage() {
  const hotlink = router.currentRoute.value.params.hotlinkTemplate
  await storePages.receberPorHotlink(hotlink)
}

const componentMapMobile = shallowRef({
  Nav1Mobile: importComponent('nav', '1'),
  Nav2Mobile: importComponent('nav', '2'),
  Nav3Mobile: importComponent('nav', '3'),
  Nav4Mobile: importComponent('nav', '4'),
  Topo1Mobile: importComponent('topo', '1'),
  Topo2Mobile: importComponent('topo', '2'),
  Topo3Mobile: importComponent('topo', '3'),
  Topo4Mobile: importComponent('topo', '4'),
  Topo5Mobile: importComponent('topo', '5'),
  Quadro1Mobile: importComponent('quadro', '1'),
  Quadro2Mobile: importComponent('quadro', '2'),
  Quadro3Mobile: importComponent('quadro', '3'),
  Duvida1Mobile: importComponent('duvida', '1'),
  Formulario1Mobile: importComponent('formulario', '1'),
  Formulario2Mobile: importComponent('formulario', '2'),
  Formulario3Mobile: importComponent('formulario', '3'),
  Formulario4Mobile: importComponent('formulario', '4'),
  Formulario5Mobile: importComponent('formulario', '5'),
  Formulario6Mobile: importComponent('formulario', '6'),
  Formulario7Mobile: importComponent('formulario', '7'),
  Plano1Mobile: importComponent('plano', '1'),
  Plano2Mobile: importComponent('plano', '2'),
  Plano3Mobile: importComponent('plano', '3'),
  Slide1Mobile: importComponent('slide', '1'),
  Footer1Mobile: importComponent('footer', '1'),
  Footer2Mobile: importComponent('footer', '2'),
  Html1Mobile: importComponent('html', '1'),
  Html2Mobile: importComponent('html', '2')
})

function importComponent(section, version) {
  const sectionCapitalized = section.charAt(0).toUpperCase() + section.slice(1)
  return defineAsyncComponent(() => import(`../secoes/padrao/${section}/${version}/versaoMobile/${sectionCapitalized}.vue`))
}

function renderizarComponentes() {
  state.componentesMobile = storeCriar.componentes.mobile
}

onMounted(() => {
  carregarPage()
  renderizarComponentes()
  emitter.on('renderizarComponentesTela', renderizarComponentes)
})
</script>
