<template>
  <div id="templates">
    <ModalCriarComTemplate />
    <ModalVerTemplate />
    <ModalCriarDoZero />
    <ModalOpcoes />
    <SectionCriar />
    <SectionLista />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStorePerfil, useStoreTemplates, useStorePages } from '@stores'
import ModalCriarComTemplate from '@components/app/templates/modals/ModalCriarComTemplate.vue'
import ModalVerTemplate from '@components/app/templates/modals/ModalVerTemplate.vue'
import ModalCriarDoZero from '@components/app/sites/modals/ModalCriarDoZero.vue'
import ModalOpcoes from '@components/app/sites/modals/ModalOpcoes.vue'
import SectionLista from '@components/app/templates/partials/SectionLista.vue'
import SectionCriar from '@components/app/templates/partials/SectionCriar.vue'
import Footer from '@components/global/footer/Footer.vue'

const storePages = useStorePages()
const storePerfil = useStorePerfil()
const storeTemplates = useStoreTemplates()

function carregarPerfil() {
  storePages.receberTodos()
  storeTemplates.receberTodos()
  storePerfil.receberPerfilUsuario()
}

onMounted(() => {
  carregarPerfil()
})
</script>

<style scoped>
#templates {
  animation: fadeIn 0.3s linear;
}
</style>
